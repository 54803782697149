import toast from 'react-hot-toast';
import i18n from '../services/i18n';
import {isLogoutErrorVisible} from "../store/services/axios";

export const showToast = (
    message?: string,
    backendErrorName?: string,
    exceptions?: string[],
) => {
    if(!isLogoutErrorVisible())
    {
        if (
            backendErrorName &&
            exceptions &&
            exceptions.find((a) => backendErrorName.includes(a || ''))
        ) {
            return;
        }

        if (message) {
            toast(message);
        } else {
            showToast(i18n.t<string>('common:undefinedError'));
        }
    }

};
