import {call, put, select, takeLatest} from 'redux-saga/effects';
//Actions
import * as actions from '../../actions';
//Services
import {api} from '../../services/axios';
//Types
import {AxiosResponse} from 'axios';
import {ReduxState} from '../../types';
import {PhoneBookListItem} from '../../reducers/phoneBook/reducer';
import {PhoneBookType} from '../../types/PhoneBook';
import JSONFormData from '../../../utils/JSONFormData';
import {showToast} from '../../../utils/showToast';
import {ActionType} from 'typesafe-actions';
import {
    PhoneBookAddNewtRequestPayload,
    PhoneBookDeleteRequestPayload,
    PhoneBookListRequestPayload,
} from '../../actions/phoneBook/payloads';
import i18n from '../../../services/i18n';
import camelCase from 'camelcase';
import {Customer} from '../../../services/endpoints';
import {CustomerInfo} from '../../types/CustomerInfo';

export type StatusFilterType = {
    title: string;
    details: {
        sip_status: number | null;
        blocked: string;
    };
};

function* fetchPhoneBookListNoPaging() {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    //@ts-ignore
    return yield api.post(Customer.GetAbbreviatedDialingNumberList, body);
}

function* fetchPhoneBookList(params: PhoneBookListRequestPayload) {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(params);
    
    //@ts-ignore
    return yield api.post(Customer.GetAbbreviatedDialingNumberList, body);
}

export function* fetchAddPhoneBookList(
    payload: Omit<PhoneBookAddNewtRequestPayload, 'callback'>,
) {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const { max_abbreviated_length, phonesBookList } = yield select(
        (state: ReduxState) => state.phoneBook,
    );
    const body = new JSONFormData(session_id, csrf_token);
    if (
        (payload.abbreviated_number &&
            payload.abbreviated_number?.length > max_abbreviated_length) ||
        !max_abbreviated_length ||
        !phonesBookList?.items?.length
    ) {
        body.setParams({
            customer_info: {
                max_abbreviated_length: payload.abbreviated_number?.length,
            },
        });
        yield api.post(Customer.UpdateCustomer, body);
    }

    body.setParams({
        abbreviated_dialing_number_info: {
            abbreviated_number: payload.abbreviated_number,
            number_to_dial: payload.number_to_dial,
            description: payload.description,
        },
    });
    //@ts-ignore
    return yield api.post(Customer.AddAbbreviatedDialingNumber, body);
}

function* fetchDeletePhoneBook(
    payload: Omit<PhoneBookDeleteRequestPayload, 'callback'>,
) {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

    for (let i = 0; i < payload.itemsToDelete.length; i++) {
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            i_ab_dialing: payload.itemsToDelete[i],
        });
        yield api.post(Customer.DeleteAbbreviatedDialingNumber, body);
    }
    return true;
}

function* fetchUpdatePhoneBook(
    payload: Omit<PhoneBookAddNewtRequestPayload, 'callback'>,
) {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const { max_abbreviated_length } = yield select(
        (state: ReduxState) => state.phoneBook,
    );
    const body = new JSONFormData(session_id, csrf_token);

    if (
        payload.abbreviated_number &&
        payload.abbreviated_number?.length > max_abbreviated_length
    ) {
        body.setParams({
            customer_info: {
                max_abbreviated_length: payload.abbreviated_number?.length,
            },
        });
        yield api.post(Customer.UpdateCustomer, body);
    }

    body.setParams({
        abbreviated_dialing_number_info: {
            abbreviated_number: payload.abbreviated_number,
            number_to_dial: payload.number_to_dial,
            description: payload.description,
            i_ab_dialing: payload.i_ab_dialing,
        },
    });

    //@ts-ignore
    return yield api.post(Customer.UpdateAbbreviatedDialingNumber, body);
}

export function* getPhoneBookList(
    action: ActionType<typeof actions.getPhoneBookList.request>) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res: AxiosResponse<any> = yield fetchPhoneBookList(action.payload);

        const phoneBookMapResult = res.data.abbreviated_dialing_number_list.map(
            (i: PhoneBookType) => {
                const item: PhoneBookListItem = {
                    id: i.i_ab_dialing || 0,
                    description: i.description,
                    abbreviated_number: i.abbreviated_number,
                    number_to_dial: i.number_to_dial,
                };
                return item;
            },
        );
        yield put(
            actions.getPhoneBookList.success({
                items: phoneBookMapResult,
                total: res.data.total,
            }),
        );
    } catch (err) {
        //@ts-ignore
        showToast(err.response?.data?.faultstring);
        //@ts-ignore
        yield put(actions.getPhoneBookList.failure(err));
    }
}

export function* getMaxAbbreviated() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const response: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );

        yield put(
            actions.getMaxAbbreviated.success({
                max_abbreviated_length:
                    response.data.customer_info.max_abbreviated_length,
            }),
        );
    } catch (err) {
        //@ts-ignore
        showToast(err.response?.data?.faultstring);
    }
}

export function* addNewPhoneBook(
    action: ActionType<typeof actions.addNewPhoneBook.request>,
) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if(action.payload.isEdited) {
            yield call(fetchUpdatePhoneBook, action.payload);
        } else {
            yield call(fetchAddPhoneBookList, action.payload);
        }

        const { max_abbreviated_length } = yield select(
            (state: ReduxState) => state.phoneBook,
        );
        let newLen: number | undefined = max_abbreviated_length;
        if (
            (action.payload.abbreviated_number &&
                action.payload.abbreviated_number?.length > max_abbreviated_length) ||
            !max_abbreviated_length
        ) {
            newLen = action.payload.abbreviated_number?.length;
        }

        yield put(actions.addNewPhoneBook.success({max_abbreviated_length: newLen}));
        action.payload.callback?.();
        showToast(
            action.payload.isEdited
                ? i18n.t<string>('screens:phoneBook.updateSuccess')
                : i18n.t<string>('screens:phoneBook.addSuccess'),
        );
    } catch (err) {
        if (
            !i18n.exists(
                //@ts-ignore
                'errors:phoneBook.' + camelCase(err.response?.data?.faultcode),
            )
        ) {
            //@ts-ignore
            showToast(err.response?.data?.faultstring);
        }

        //@ts-ignore
        yield put(actions.addNewPhoneBook.failure(err.response.data));
    }
}

export function* deletePhoneBook(
    action: ActionType<typeof actions.deletePhoneBook.request>,
) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res: AxiosResponse<any> = yield fetchDeletePhoneBook(
            action.payload,
        );
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

        if (res) {
            //@ts-ignore
            const phonesBookList = (yield call(fetchPhoneBookListNoPaging)).data.abbreviated_dialing_number_list ?? [];

            const { max_abbreviated_length } = yield select(
                (state: ReduxState) => state.phoneBook,
            );
            const maxLength =
                phonesBookList.length > 0
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ? phonesBookList.reduce((a: any, b: any) =>
                        a.abbreviated_number.length >
                        b.abbreviated_number.length
                            ? a
                            : b,
                    )
                    : null;
            const newLength = maxLength?.abbreviated_number?.length || 1;
            if (newLength !== max_abbreviated_length) {
                const body = new JSONFormData(session_id, csrf_token);
                body.setParams({
                    customer_info: {
                        max_abbreviated_length: newLength
                    },
                });
                yield api.post(Customer.UpdateCustomer, body);
                yield put(actions.deletePhoneBook.success({max_abbreviated_length: newLength}));
            } else {
                yield put(actions.deletePhoneBook.success(undefined));
            }
        }

        action.payload.callback?.();
        showToast(i18n.t<string>('screens:phoneBook.deleteSuccessfully'));
    } catch (err) {
        //@ts-ignore
        showToast(err.response?.data?.faultstring);
        //@ts-ignore
        yield put(actions.deletePhoneBook.failure(err));
    }
}

export const phoneBookSaga = [
    takeLatest(actions.getPhoneBookList.request, getPhoneBookList),
    takeLatest(actions.addNewPhoneBook.request, addNewPhoneBook),
    takeLatest(actions.deletePhoneBook.request, deletePhoneBook),
    takeLatest(actions.getMaxAbbreviated.request, getMaxAbbreviated),
];
