import React, {useRef} from "react";
import {AccountListRequest, AccountListResponse} from "../../store/types/Account";
import JSONFormData from "../../utils/JSONFormData";
import {AxiosResponse} from "axios";
import {api} from "../../store/services/axios";
import {Account} from "../../services/endpoints";
import {useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import AsyncSelectField from "../AsyncSelectField/AsyncSelectField";
import {mapToItem} from "../../utils/transformers";
import {ExtensionsListItem} from "../../store/reducers/extensions/extensions/reducer";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";

interface ExtensionAsyncSelectFiledProps {
    i_customer?: string | number,
    value?: ExtensionsListItem,
    setValue: (value: ExtensionsListItem) => void,
    ignoreIds?: string [],
    disableUnRegistered?: boolean,
    customClass?: string,
    defaultOptions?: boolean;
    required?: boolean,
    useAny?: boolean,
    showRegisterStatus?: boolean,
    disableClearable?: boolean,
    forceEnable?: boolean,
    useExtensionFilter?: boolean,
    setFieldError?: (name: string, value: string | undefined) => void;
    id?: string,
    helperText?: string

}

const useStyles = makeStyles(() => ({
    option: {
        display: 'flex',
        flexDirection: 'column',
        '& .label': {
            lineHeight: '48px',
            color: Colors.Black,
            fontSize: 16,
            weight: 400
        },

        '& .register, & .unregister': {
            fontSize: 14,
            weight: 400,
            lineHeight: '16px',
        },

        '& .register': {
            color: Colors.Support
        },
        '& .unregister': {
            color: Colors.Primary
        }
    },
    disabledOption: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.Gray + '!important',

        '& .label': {
            lineHeight: '48px',
            fontSize: 16,
            weight: 400
        },

        '& .register, & .unregister': {
            fontSize: 14,
            weight: 400,
            lineHeight: '16px',
        },
    }
}))
const ExtensionAsyncSelectFiled: React.VFC<ExtensionAsyncSelectFiledProps> = (
    {
        i_customer,
        value,
        setValue,
        ignoreIds,
        disableUnRegistered,
        customClass,
        defaultOptions = false,
        required = true,
        useAny = false,
        showRegisterStatus = true,
        disableClearable = false,
        forceEnable = false,
        useExtensionFilter = false,
        setFieldError,
        id,
        helperText

    }) => {

    const {session_id, csrf_token} = useSelector((state: ReduxState) => state.auth);
    const classes = useStyles();
    const {t} = useTranslation();
    const anyAdded = useRef(false)

    const emptyValueForAny = {label: t('common:any'), value: undefined, id: '-1'}

    const loadOptions = async (search: string, prevOptions: unknown[]) => {
        const limit = 30;

        const params: Partial<AccountListRequest> = {
            id: search && !useExtensionFilter ? `%${search}%` : undefined,
            extension_id: search && useExtensionFilter ? `%${search}%` : undefined,
            i_customer: i_customer,
            has_extension: 1,
            get_not_closed_accounts: 1,
            get_only_real_accounts: 1,
            get_total: 1,
            limit: 30,
            offset: prevOptions.length,
        };

        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<AccountListResponse> = await api.post(
            Account.GetAccountList,
            body,
        );
        const items = res.data.account_list.map(item => ({
            ...item,
            label: item.extension_id + ' :' + item.extension_name,
            value: item.id
        }));

        if (useAny && !anyAdded.current) {
            // @ts-ignore
            items.unshift(emptyValueForAny)
            anyAdded.current = true
        }

        return {
            options: items,
            hasMore: res.data.total > prevOptions.length + limit,
        };
    };


    return (
        <AsyncSelectField
            id={id}
            forceEnabled={forceEnable}
            disabled={false}
            title={t('screens:dashboard.extension')}
            loadOptions={loadOptions}
            value={value}
            onChange={(value) => {

                if (useAny)
                    anyAdded.current = false

                if (value.value == undefined && useAny) {
                    // @ts-ignore
                    setValue(emptyValueForAny);
                } else {
                    setValue(value ? mapToItem(value) : value);
                }

            }}
            isOptionSelected={(option) => {
                return option.id == value?.id;
            }}
            defaultValue={{label: t('common:any'), value: undefined}}
            className={customClass}
            required={required}
            defaultOptions={defaultOptions || false}
            formatOptionLabel={(option, labelMeta) => {

                const registerText = option.sip_status ? t('screens:dashboard.registered') : t('screens:dashboard.unregistered');
                const classNameValue = option.sip_status ? 'register' : 'unregister';

                return <>
                    <div
                        className={disableUnRegistered && option.sip_status == 0 ? classes.disabledOption : classes.option}>
                        <span>{option.extension_name ? option.extension_id + ' - ' + option.extension_name : useAny && !option.extension_id ? option.label : option.extension_id}</span>

                        {labelMeta.context == 'menu' && showRegisterStatus && (
                            <span className={classNameValue}>
                            {registerText}
                        </span>)
                        }

                    </div>
                </>
            }}

            filterOption={ignoreIds ? (option) => {
                return ignoreIds.find(item => item == option.value) == undefined;
            } : undefined}

            isOptionDisabled={disableUnRegistered ? (option) => {
                return option.sip_status == 0
            } : undefined}

            disableClearable={disableClearable}
            useAny={useAny}
            cacheUniqs={[value, t('common:any')]}
            setFieldError={setFieldError}
            helperText={helperText}

        />
    )

}

export default ExtensionAsyncSelectFiled;