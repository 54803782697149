import {ActionType, createReducer} from 'typesafe-actions';
import * as actions from '../../actions/calls';
import {ActiveCall, CallHistory} from '../../types/CallHistory';
import {WidgetCallHistory} from '../../types/Wallboard';
import {ExtensionType} from '../../types/Extension';

export type PlayingFile = {
    i_xdr: number;
    call_recording_id: string;
    h323_conf_id?: string
};

export enum RecordingsPlayingStatus {
    notDefined = 0,
    downloading = 1,
    downloadedNotStartedPlaying = 2,
    playing = 3,
    paused = 4,
    finished = 5,
    dowloadingRestricted = 6
};

export type CallSegment = {call_recording_id: string; segment_id: number}

export type RecordingsPlayingState = {
    filesQueue: PlayingFile[][];
    status: number;
    currentFile: PlayingFile | null;
    currentBlob: string | null;
};

export type TranscriptionSegment = {
    duration: string,
    intervals?: {start:string, end:string}
    text: string
}

export type Segment = {
    segment: TranscriptionSegment,
    segment_id: number,
    speaker: number
}

export type TranscriptionData = {
    call_recording_id: string
    segments: Segment[]
}

export enum ContentTypes {
    ZIP = 'application/zip',
    TXT = 'text/plain',
    JSON = 'application/json',
    ISO_TEXT = 'text/plain; charset=ISO-8859-1'
}

export enum FormatType {
    JSON = 'json',
    TXT = 'text'
}

export interface CallsStateType {
    customerCurrency?: string;
    extensionCallHistory: {
        total: number;
        items: CallHistory[];
        isLoading: boolean;
    };
    callHistory: {
        total: number;
        items: CallHistory[];
        isLoading: boolean;
        allTotal: number;
        isRemovingGoingOn?: boolean;
    };
    activeCalls: {
        total: number;
        items: ActiveCall[];
        isLoading: boolean;
        refreshTime: string;
    };
    areCallsLoading?: boolean;
    todayCalls: number;
    yesterdayCalls: number;
    recordingsPlayer: RecordingsPlayingState;
    internalRecordingsPlayer: RecordingsPlayingState;
    isStatisticLoading?: boolean;
    callsStatistic?: WidgetCallHistory[];
    callsStatisticTotal?: number,
    callsStatisticUpdateDateFrom?: string;
    callsStatisticUpdateDateTo?: string;
    callsDoughnutExtensionsList?: ExtensionType[];
    isCallsDoughnutExtensionsLoading?: boolean;
    getCallHistoryBasicDataRefresh: boolean,
    isTranscriptionAvailable: boolean
    isTranscriptionDownloading: boolean
    fetchTranscription: boolean
    transcription?: TranscriptionData
}

export const initialState: CallsStateType = {
    extensionCallHistory: {
        total: 0,
        items: [],
        isLoading: false,
    },
    callHistory: {
        total: 0,
        items: [],
        isLoading: false,
        isRemovingGoingOn: false,
        allTotal: 0,
    },
    activeCalls: {
        total: 0,
        items: [],
        isLoading: false,
        refreshTime: '',
    },
    todayCalls: 0,
    yesterdayCalls: 0,
    recordingsPlayer: {
        filesQueue: [],
        status: RecordingsPlayingStatus.notDefined,
        currentFile: null,
        currentBlob: null
    },
    internalRecordingsPlayer: {
        filesQueue: [],
        status: RecordingsPlayingStatus.notDefined,
        currentFile: null,
        currentBlob: null
    },
    getCallHistoryBasicDataRefresh: false,
    isTranscriptionAvailable: false,
    isTranscriptionDownloading: false,
    fetchTranscription: false
};

export type CallsActionsType = ActionType<typeof actions>;

const callsReducer = createReducer<CallsStateType, CallsActionsType>(
    initialState,
)
    .handleAction(actions.getExtensionCallHistory.request, (state) => ({
        ...state,
        extensionCallHistory: {
            ...state.extensionCallHistory,
            items: [],
            total: 0,
            isLoading: true,
        },
    }))
    .handleAction(actions.getExtensionCallHistory.success, (state, action) => ({
        ...state,
        extensionCallHistory: {
            total: action.payload.total,
            items: action.payload.xdr_list,
            isLoading: false,
        },
    }))
    .handleAction(actions.getExtensionCallHistory.failure, (state) => ({
        ...state,
        extensionCallHistory: {
            ...state.extensionCallHistory,
            isLoading: false,
        },
    }))
    .handleAction(
        actions.setCustomerCurrencyForCallHistory,
        (state, action) => ({
            ...state,
            customerCurrency: action.payload.currency,
        }),
    )
    .handleAction(actions.getCallHistory.request, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            items: [],
            allTotal: 0,
            isLoading: true,
        },
    }))
    .handleAction(actions.getCallHistory.success, (state, action) => ({
        ...state,
        callHistory: {
            total: action.payload.total,
            items: action.payload.xdr_list,
            isLoading: false,
            allTotal: state.callHistory.allTotal || action.payload.total,
        },
    }))
    .handleAction(actions.getCallHistory.failure, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            isLoading: false,
        },
    }))
    .handleAction(actions.removeCallRecordings.request, (state) => ({
        ...state,
        extensionCallHistory: {
            ...state.extensionCallHistory,
            isLoading: true,
        },
        callHistory: {
            ...state.callHistory,
            isRemovingGoingOn: true,
        },
    }))
    .handleAction(actions.removeCallRecordings.success, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            isRemovingGoingOn: false,
        },
    }))
    .handleAction(actions.removeCallRecordings.failure, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            isRemovingGoingOn: false,
        },
    }))
    .handleAction(actions.getCallHistoryBasicData.request, (state) => ({
        ...state,
        getCallHistoryBasicDataRefresh: true,
        isCallsDoughnutExtensionsLoading: true,

    }))
    .handleAction(actions.getCallHistoryBasicData.success, (state, action) => ({
        ...state,
        ...action.payload,
        getCallHistoryBasicDataRefresh: false,

    }))
    .handleAction(actions.getCallHistoryBasicData.failure, (state) => ({
        ...state,
        getCallHistoryBasicDataRefresh: false,

    }))
    .handleAction(actions.getInitialCallHistoryBasicData.request, (state) => ({
        ...state,
        areCallsLoading: true,
    }))
    .handleAction(actions.getInitialCallHistoryBasicData.success, (state) => ({
        ...state,
        areCallsLoading: false,
    }))
    .handleAction(actions.getInitialCallHistoryBasicData.failure, (state) => ({
        ...state,
        areCallsLoading: false,
    }))
    .handleAction(actions.updateRecordingsPlayingState, (state, action) => ({
        ...state,
        recordingsPlayer: action.payload,
    }))
    .handleAction(actions.internalUpdateRecordingsPlayingState, (state, action) => ({
        ...state,
        internalRecordingsPlayer: action.payload,
    }))
    .handleAction(actions.getCallHistoryDataForStatistics.request, (state) => ({
        ...state,
        isStatisticLoading: true,
        callsStatisticUpdateDateFrom: undefined,
        callsStatisticUpdateDateTo: undefined,
        callsStatisticTotal: undefined
    }))
    .handleAction(actions.getCallHistoryDataForStatistics.success, (state, action) => ({
        ...state,
        isStatisticLoading: false,
        callsStatistic: action.payload.calls,
        callsStatisticTotal: action.payload.callsTotal,
        callsStatisticUpdateDateFrom: action.payload.lastDateFrom,
        callsStatisticUpdateDateTo: action.payload.lastDateTo,
    }))
    .handleAction(actions.getCallHistoryDataForStatistics.failure, (state) => ({
        ...state,
        isStatisticLoading: false,
        callsStatistic: undefined,
        callsStatisticTotal: undefined,
    }))
    .handleAction(actions.getExtensionsListForCallsDoughnut.request, (state) => {

        return {
            ...state,
            callsDoughnutExtensionsList: undefined,
            isCallsDoughnutExtensionsLoading: true,
        }
    })
    .handleAction(actions.getExtensionsListForCallsDoughnut.success, (state, action) => {
        return {
            ...state,
            callsDoughnutExtensionsList: action.payload,
            isCallsDoughnutExtensionsLoading: false,
        }
    })
    .handleAction(actions.getExtensionsListForCallsDoughnut.failure, (state) => ({
        ...state,
        callsDoughnutExtensionsList: undefined,
        isCallsDoughnutExtensionsLoading: false,
    }))
    .handleAction(actions.checkIfTranscriptionExist.request, (state) => ({
        ...state,
        isTranscriptionAvailable: false,
    }))
    .handleAction(actions.checkIfTranscriptionExist.success, (state, action) => ({
        ...state,
        transcription: action.payload.transcriptionExist ? state.transcription : undefined,
        isTranscriptionAvailable: action.payload.transcriptionExist,
    }))
    .handleAction(actions.checkIfTranscriptionExist.failure, (state) => ({
        ...state,
        isTranscriptionAvailable: false,
    })).handleAction(actions.downloadTranscriptionFile.request, (state) => ({
        ...state,
        isTranscriptionDownloading: true,
    }))
    .handleAction(actions.downloadTranscriptionFile.success, (state) => ({
        ...state,
        isTranscriptionDownloading: false,
    }))
    .handleAction(actions.downloadTranscriptionFile.failure, (state) => ({
        ...state,
        isTranscriptionDownloading: false,
    })).handleAction(actions.fetchTranscription.request, (state) => ({
        ...state,
        fetchTranscription: true,
        transcription: undefined
    }))
    .handleAction(actions.fetchTranscription.success, (state, action) => ({
        ...state,
        fetchTranscription: false,
        transcription: action.payload
    }))
    .handleAction(actions.fetchTranscription.failure, (state) => ({
        ...state,
        fetchTranscription: false,
        transcription: undefined,
        isTranscriptionAvailable:false
    }));

export default callsReducer;
