import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from '../../actions';
import {api} from '../../services/axios';
import {AxiosResponse} from 'axios';
import {ReduxState} from '../../types';
import JSONFormData from '../../../utils/JSONFormData';
import dayjs from '../../../services/customDayJs';
import {CounterInterface, CustomerAgreementCondition} from '../../types/CustomerAgreementCondition';
import {CustomerNumberType} from '../../types/CustomerDIDNumber';
import {Account, Customer, DID, UA,} from '../../../services/endpoints';
import {convertUserLocalTimeToUtc} from '../../../utils/dateWithTimezoneConversion';
import {getSessionData} from '../generic/saga';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export function* getDashboardData() {
    yield put(actions.accessDeniedToDidNumbers(false));
    yield call(getSessionData);

    yield all([
        call(getRegisteredExtensions),
        call(getUnregisteredExtensions),
        call(getCallsFromLastDays),
        call(getDidNumbers),
        call(getAllDevices),
        call(getInUseDevices),
        call(getNotInUseDevices),
        call(getProducts),
        call(getBundles)
    ]);
}

export function* getRegisteredExtensions() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            has_extension: 1,
            get_only_real_accounts: 1,
            get_not_closed_accounts: 1,
            get_total: 1,
            limit: 1,
            offset: 0,
            sip_status: 1,
        });

        const res: AxiosResponse<{
            total: number;
        }> = yield api.post(Account.GetAccountList, body);

        yield put(actions.setRegisteredExtensions(res.data.total));
    } catch (err) {
        yield put(actions.setRegisteredExtensions(undefined));
    }
}

export function* getUnregisteredExtensions() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            has_extension: 1,
            get_only_real_accounts: 1,
            get_not_closed_accounts: 1,
            get_total: 1,
            limit: 1,
            offset: 0,
            sip_status: 0,
        });

        const res: AxiosResponse<{
            total: number;
        }> = yield api.post(Account.GetAccountList, body);

        yield put(actions.setUnregisteredExtensions(res.data.total));
    } catch (err) {
        yield put(actions.setUnregisteredExtensions(undefined));
    }
}

export function* getCallsFromLastDays() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const { tz_offset } = yield select(
            (state: ReduxState) => state.generic.sessionData,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const totalValues: { date: string; value: number }[] = [];

        for (let i = 7; i >= 0; i--) {
            body.setParams({
                show_unsuccessful: '0',
                i_service_type: '3',
                get_total: '1',
                limit: '1',
                from_date: convertUserLocalTimeToUtc(
                    dayjs
                        .utc()
                        .subtract(i, 'day')
                        .startOf('day')
                        .format(dateFormat),
                    tz_offset,
                ),
                to_date: convertUserLocalTimeToUtc(
                    dayjs
                        .utc()
                        .subtract(i, 'day')
                        .endOf('day')
                        .format(dateFormat),
                    tz_offset,
                ),
            });

            const res: AxiosResponse<{
                total: number;
            }> = yield api.post(Customer.GetCustomerXDRS, body);

            totalValues.push({
                date: dayjs
                    .utc()
                    .subtract(i, 'day')
                    .startOf('day')
                    .format(dateFormat),
                value: res.data.total,
            });
        }

        yield put(actions.setCallsFromLastDays(totalValues));
    } catch (err) {
        yield put(actions.setCallsFromLastDays(undefined));
    }
}

export function* getCallsConnected() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const date = dayjs.utc();

        body.setParams({
            show_unsuccessful: '0',
            i_service_type: '3',
            get_total: '1',
            limit: '1',
            from_date: date.startOf('month').format(dateFormat),
            to_date: date.format(dateFormat),
        });

        const res: AxiosResponse<{
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);

        yield put(actions.setCallsConnected(res.data.total));
    } catch (err) {
        yield put(actions.setCallsConnected(undefined));
    }
}

export function* getDidNumbers() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        let allNumbers = 0;
        let inUseNumbers = 0;
        let notInUseNumbers = 0;

        let page = 0;

        while (true) {
            body.setParams({
                get_total: '1',
                limit: '50',
                offset: page * 50,
            });

            const res: AxiosResponse<CustomerNumberType> = yield api.post(
                DID.GetCustomerNumbers,
                body,
            );

            allNumbers = res.data.total;

            res.data.number_list.forEach((v) => {
                if (v.i_account || v.i_master_account) {
                    inUseNumbers++;
                } else {
                    notInUseNumbers++;
                }
            });

            if (inUseNumbers + notInUseNumbers >= res.data.total) {
                break;
            }

            page++;
        }

        yield put(actions.setAllDidNumbers(allNumbers));
        yield put(actions.setInUseDidNumbers(inUseNumbers));
        yield put(actions.setNotInUseDidNumbers(notInUseNumbers));
    } catch (err) {

        // @ts-ignore
        if(err.response?.data?.faultcode  === 'Server.DID.get_customer_numbers.access_denied' )
        {
            yield put(actions.accessDeniedToDidNumbers(true));
        }

        yield put(actions.setAllDidNumbers(undefined));
        yield put(actions.setInUseDidNumbers(undefined));
        yield put(actions.setNotInUseDidNumbers(undefined));
    }
}

export function* getAllDevices() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            get_total: '1',
            limit: '1',
        });

        const res: AxiosResponse<{
            total: number;
        }> = yield api.post(UA.GetUaList, body);

        yield put(actions.setAllDevices(res.data.total));
    } catch (err) {
        yield put(actions.setAllDevices(undefined));
    }
}

export function* getInUseDevices() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            get_total: '1',
            limit: '1',
            usage: 'U',
        });

        const res: AxiosResponse<{
            total: number;
        }> = yield api.post(UA.GetUaList, body);

        yield put(actions.setInUseDevices(res.data.total));
    } catch (err) {
        yield put(actions.setInUseDevices(undefined));
    }
}

export function* getNotInUseDevices() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            get_total: '1',
            limit: '1',
            usage: 'F',
        });

        const res: AxiosResponse<{
            total: number;
        }> = yield api.post(UA.GetUaList, body);

        yield put(actions.setNotInUseDevices(res.data.total));
    } catch (err) {
        yield put(actions.setNotInUseDevices(undefined));
    }
}

export function* getProducts() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            with_usage: '1',
        });

        const res: AxiosResponse<{
            agreement_condition_list: CustomerAgreementCondition[];
        }> = yield api.post(Customer.GetCustomerAgreementInfo, body);

        yield put(
            actions.setMainProducts(
                res.data.agreement_condition_list.filter(
                    (v) => v.is_addon === 0,
                ),
            ),
        );
        yield put(
            actions.setAddonProducts(
                res.data.agreement_condition_list.filter(
                    (v) => v.is_addon === 1,
                ),
            ),
        );
    } catch (err) {
        yield put(actions.setMainProducts(undefined));
        yield put(actions.setAddonProducts(undefined));
    }
}

export function* getBundles() {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);


        body.setParams({
            separate_units: '0',
            with_discount_info: '1',
        });

        const res: AxiosResponse<{
            counter_list: CounterInterface[];
        }> = yield api.post(Customer.GetFullVdCounterInfo, body);

        yield put(actions.setBundles(res.data.counter_list))

    } catch (err) {
        console.log(err)
    }
}

export const dashboardSaga = [
    takeLatest(actions.getDashboardData, getDashboardData),
];
