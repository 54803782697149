import React, {useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import DialogContainer, {DialogButton,} from '../../components/AlertDialog/DialogContainer';
import {useFormik} from 'formik';
import TextField from '../../components/TextField/TextField';
import {useTranslation} from 'react-i18next';
import {ReduxState} from '../../store/types';
import {CallHistoryFormProps, dateFormat, generateColumns, useStyles,} from './callHistoryUtils';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {actions} from '../../store';
import CustomKeyboardDateTimePicker from '../../components/KeyboardDateTimePicker/KeyboardDateTimePicker';
import DataGrid from '../../components/DataGrid/DataGrid';
import {PaginationMode} from '../../components/DataGrid/types';
import {RingGroupType} from '../../store/types/RingGroup';
import CustomButton from '../../components/Button/Button';
import dayjs from '../../services/customDayJs';
import {convertUserLocalTimeToUtc} from '../../utils/dateWithTimezoneConversion';
import {CallHistory} from '../../store/types/CallHistory';
import {dateFromDateTillValidationSchema} from "../../components/Extensions/CallHistoryDialog.utils";
import {showToast} from "../../utils/showToast";

interface CreateNewPortalUserDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    form: RingGroupType | null;
    customCld?: string;
    downloadPermission?: string[];
}

const CallHistoryDetails: React.VFC<CreateNewPortalUserDialogProps> = ({
    isOpen,
    toggleVisibility,
    form,
    customCld,
    downloadPermission,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );
    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const globalCurrency = useSelector(
        (state: ReduxState) => state.generic.globalCurrency,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const { items, total } = useSelector<
        ReduxState,
        { items: CallHistory[]; total: number; allTotal: number }
    >((state) => state.calls.callHistory);

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => state.calls.callHistory.isLoading,
    );

    const handleCancel = () => {
        resetForm();
        toggleVisibility?.();
    };

    const toggleTooltipVisibility = (index: number) => {
        const newDownloading = [...isDownloadingGoingOn];
        newDownloading[index] = false;
        setIsDownloadingGoingOn(newDownloading);
    };

    const downloadFile = (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => {
        const newDownloading = [...isDownloadingGoingOn];
        newDownloading[index] = true;
        setIsDownloadingGoingOn(newDownloading);
        showToast(t<string>('tooltips:ringGroups.downloadWillStartShortly'));
        dispatch(
            actions.getExtensionCallHistoryFile.request({
                i_xdr,
                call_recording_id,
                fileName,
                callback: () => toggleTooltipVisibility(index),
            }),
        );
    };

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                timezoneOffset,
                classes,
                downloadFile,
                globalCurrency?.iso_4217,
                isDownloadingGoingOn,
                userDateTimeFormat,
                downloadPermission,
            ),
        [globalCurrency, isDownloadingGoingOn, userDateTimeFormat],
    );

    const initialValues = useMemo(
        () => ({
            from: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .subtract(1, 'month')
                .format(dateFormat),
            till: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .format(dateFormat),
            caller: '',
        }),
        [],
    );

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldError,
        errors,
        resetForm,
    } = useFormik<CallHistoryFormProps>({
        initialValues,
        onSubmit: () => {
            setPage(0);
            onSubmit(undefined, 0);
        },
        validationSchema: dateFromDateTillValidationSchema(timezoneOffset, userDateTimeFormat),
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
    });

    useEffect(() => {
        if (isOpen) {
            dispatch(
                actions.getCallHistory.request({
                    limit: pageSize || 10,
                    offset: page ? page * pageSize : 0,
                    from: convertUserLocalTimeToUtc(
                        values.from,
                        timezoneOffset,
                    ),
                    till: convertUserLocalTimeToUtc(
                        values.till,
                        timezoneOffset,
                    ),
                    cld: customCld || `X${form?.id}`,
                }),
            );
            dispatch(actions.getGlobalCurrencyData.request());
        }
    }, [isOpen, customCld]);

    const onSubmit = (size?: number, page?: number) => {
        const pageSizePrepared = size ? size : pageSize;
        dispatch(
            actions.getCallHistory.request({
                limit: pageSizePrepared,
                offset: page ? page * pageSizePrepared : 0,
                from: convertUserLocalTimeToUtc(values.from, timezoneOffset),
                till: convertUserLocalTimeToUtc(values.till, timezoneOffset),
                cld: customCld || `X${form?.id || 100}`,
                cli: values.caller ? `${values.caller}%` : undefined,
            }),
        );
    };

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="call-history-form"
            header={t('screens:ringGroups.callHistoryName', {
                name: form?.name,
            })}
            className={classes.itemsContainer}
            dialogActionsButtons={[
                <DialogButton
                    key="close"
                    label={t('common:close')}
                    onClick={handleCancel}
                />,
            ]}
        >
            <div className={classNames(classes.filters)}>
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className={classes.filtersContainer}
                >
                    <Grid item className={classes.itemsContainer}>
                        <Box className={classes.rowBox}>
                            <CustomKeyboardDateTimePicker
                                label={t('common:fromDate')}
                                value={values.from}
                                onChange={(v) => setFieldValue('from', v)}
                                dataQa={'call-history-filter-from'}
                                dataTestId={'call-history-filter-from'}
                                className={classes.filterSeparation}
                                allowEmptyDate={false}
                                helperText={errors.from}
                                userDateTimeFormat={userDateTimeFormat}
                                handleSubmit={handleSubmit}
                            />
                            <CustomKeyboardDateTimePicker
                                label={t('common:toDate')}
                                value={values.till}
                                onChange={(v) => setFieldValue('till', v)}
                                dataQa={'call-history-filter-till'}
                                dataTestId={'call-history-filter-till'}
                                className={classes.filterSeparation}
                                allowEmptyDate={true}
                                helperText={errors.till}
                                userDateTimeFormat={userDateTimeFormat}
                                handleSubmit={handleSubmit}
                            />
                            <TextField
                                id="caller"
                                label={t('screens:calls.caller')}
                                onChange={handleChange}
                                value={values.caller}
                                setFieldError={setFieldError}
                                dataQa="caller"
                                helperText={errors?.caller}
                                className={classes.filterSeparation}
                            />
                            <CustomButton
                                dataQa="search-button"
                                secondary
                                type="submit"
                                className={classes.button}
                            >
                                {t('common:search')}
                            </CustomButton>
                        </Box>
                    </Grid>
                </form>
            </div>
            <DataGrid
                columns={columns}
                //@ts-ignore
                data={items}
                rowCount={total}
                onPageChange={(params) => {
                    setPage(params.page);
                    onSubmit(undefined, params.page);
                }}
                onPageSizeChange={(params) => {
                    setPageSize(params.pageSize);
                    setPage(0);
                    onSubmit(params.pageSize);
                }}
                loading={isLoading}
                initialPageSize={5}
                paginationMode={PaginationMode.Server}
                classes={{
                    tableContainer: classes.tableContainer,
                }}
                centeredRows
                narrowRows
                customRowHeight={64}
            />
        </DialogContainer>
    );
};

export default CallHistoryDetails;
