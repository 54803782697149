import {ActionType, createReducer} from 'typesafe-actions';
import dayjs from '../../../services/customDayJs';
import * as actions from '../../actions';
import {APIErrorInterface, WidgetsAPIErrorInterface} from '../../types';
import {ExtensionType} from '../../types/Extension';
import {
    BaseWallboardWidget,
    ExtensionListItemsForWidget,
    RefreshWidgetQueueItem,
    RefreshWidgetTime,
    WidgetCallHistory
} from '../../types/Wallboard';
import {RingGroupType} from '../../types/RingGroup';
import {SipCall} from '../../actions/ringgroups/payloads';
import {CustomWidgetPeriods, MultipleServiceInfo, WidgetItemCalls} from "../../types/Billing";

export interface WallboardStateType {
    widgets?: BaseWallboardWidget[];
    isLoadingData?: boolean;
    updateWidgetsError?: APIErrorInterface;
    refreshTime?: string;

    refreshWidgetsQueue: RefreshWidgetQueueItem[];
    refreshWidgetsTime: RefreshWidgetTime[];

    callHistoryItems: WidgetCallHistory[];
    historyCallsLoading: boolean;

    extensionsList: ExtensionType[];
    isLoadingExtensions: boolean;
    loadingExtensionApiError?: APIErrorInterface;

    ringGroupsList: RingGroupType[];
    isLoadingRingGroups: boolean;

    sipCallsList: SipCall[];
    isLoadingSipCalls: boolean;

    transactionList: MultipleServiceInfo [];
    isLoadingTransaction: boolean;
    customWidgetPeriods: CustomWidgetPeriods[];
    customTransactionRefreshData: WidgetItemCalls[];
    allAccountsWithTransaction: ExtensionListItemsForWidget[];
    widgetsErrors: WidgetsAPIErrorInterface;
}

export const initialState: WallboardStateType = {
    refreshWidgetsQueue: [],
    refreshWidgetsTime: [],
    callHistoryItems: [],
    historyCallsLoading: false,
    extensionsList: [],
    isLoadingExtensions: false,
    ringGroupsList: [],
    isLoadingRingGroups: false,
    sipCallsList: [],
    isLoadingSipCalls: false,
    transactionList: [],
    isLoadingTransaction: false,
    customWidgetPeriods: [],
    customTransactionRefreshData: [],
    allAccountsWithTransaction: [],
    widgetsErrors:{}
};

export type wallboardActionsType = ActionType<typeof actions>;

const wallboardReducer = createReducer<
        WallboardStateType,
        wallboardActionsType
    >(initialState)
        .handleAction(actions.getWallboardData.request, (state) => ({
            ...state,
            isLoadingData: true
        }))
        .handleAction(actions.getWallboardData.success, (state, action) => ({
            ...state,
            isLoadingData: false,
            widgets: action.payload,
            refreshTime: dayjs().format('YYYY-MM-DD HH:mm:ss')
        }))
        .handleAction(actions.getWallboardData.failure, (state) => ({
            ...state,
            isLoadingData: false,
            refreshTime: undefined
        }))
        .handleAction(actions.updateWallboards.request, (state) => ({
            ...state,
            updateWidgetsError: undefined
        }))
        .handleAction(actions.updateWallboards.success, (state, action) => ({
            ...state,
            updateWidgetsError: undefined,
            widgets: action.payload
        }))
        .handleAction(actions.updateWallboards.failure, (state, action) => ({
            ...state,
            updateWidgetsError: action.payload
        }))
        .handleAction(actions.refreshWallboardTrigger.success, (state, action) => ({
            ...state,
            refreshWidgetsQueue: [...action.payload.refreshWidgetsQueue],
        }))
        .handleAction(actions.refreshWallboardTrigger.failure, (state) => ({
            ...state,
        }))
        .handleAction(actions.refreshWidgetCompleted.success, (state, action) => ({
            ...state,
            refreshWidgetsQueue: [...action.payload.refreshWidgetsQueue],
            refreshWidgetsTime: [...(action.payload.refreshWidgetsTime || state.refreshWidgetsTime)],
        }))
        .handleAction(actions.refreshWidgetCompleted.failure, (state) => ({
            ...state,
        }))
        .handleAction(actions.getCallHistoryDataForWidget.request, (state) => ({
            ...state,
            historyCallsLoading: true
        }))
        .handleAction(actions.getCallHistoryDataForWidget.success, (state, action) => ({
            ...state,
            historyCallsLoading: false,
            callHistoryItems: action.payload,
        }))
        .handleAction(actions.getCallHistoryDataForWidget.failure, (state) => ({
            ...state,
            historyCallsLoading: false,
        }))
        .handleAction(actions.getExtensionsListForWidgets.request, (state) => ({
            ...state,
            isLoadingExtensions: true,
            loadingExtensionApiError: undefined,
        }))
        .handleAction(actions.getExtensionsListForWidgets.success, (state, action) => ({
            ...state,
            extensionsList: action.payload,
            isLoadingExtensions: false,
            loadingExtensionApiError: undefined,
        }))
        .handleAction(actions.getExtensionsListForWidgets.failure, (state, action) => ({
            ...state,
            isLoadingExtensions: false,
            loadingExtensionApiError: action.payload,
        }))
        .handleAction(actions.getRingGroupsListForWidgets.request, (state) => ({
            ...state,
            isLoadingRingGroups: true
        }))
        .handleAction(actions.getRingGroupsListForWidgets.success, (state, action) => ({
            ...state,
            ringGroupsList: action.payload,
            isLoadingRingGroups: false
        }))
        .handleAction(actions.getRingGroupsListForWidgets.failure, (state) => ({
            ...state,
            isLoadingRingGroups: false
        }))
        .handleAction(actions.getTransactionsListForWidgets.request, (state) => ({
            ...state,
            isLoadingTransaction: true
        }))
        .handleAction(actions.getTransactionsListForWidgets.success, (state, action) => {

            const temp = [...state.transactionList]
            const ids = temp.map(el => el.id);

            action.payload.forEach(el => {
                if (ids.includes(el.id)) {
                    const index = ids.findIndex(v => v == el.id);
                    temp[index] = el
                } else {
                    temp.push(el)
                }
            })

            return {
                ...state,
                transactionList: temp,
                isLoadingTransaction: false
            }
        })
        .handleAction(actions.getTransactionsListForWidgets.failure, (state,action) => ({
            ...state,
            isLoadingTransaction: false,
            widgetsErrors:{...state.widgetsErrors, ...action.payload}
        }))
        .handleAction(actions.getSipCallsListForWidgets.request, (state) => ({
            ...state,
            isLoadingSipCalls: true
        }))
        .handleAction(actions.getSipCallsListForWidgets.success, (state, action) => ({
            ...state,
            sipCallsList: action.payload,
            isLoadingSipCalls: false
        }))
        .handleAction(actions.getSipCallsListForWidgets.failure, (state) => ({
            ...state,
            isLoadingSipCalls: false
        }))
        .handleAction(actions.validatesAndPostDataToQueue.success, (state, action) => ({
            ...state,
            refreshWidgetsQueue: [...action.payload]
        }))
        .handleAction(actions.updateSipCallStatusForWidgetsByWebSocketEvent.success, (state, action) => ({
            ...state,
            sipCallsList: action.payload
        }))
        .handleAction(actions.callAllTransactionDataForWidget.request, (state, action) => {

            const {id, init, meta} = action.payload

            if (init) {
                const tempState = [...state.allAccountsWithTransaction];
                tempState.push({
                    widgetId: id,
                    init,
                    meta: {init, id, meta: {...meta}},
                    items: []
                })

                return {
                    ...state,
                    allAccountsWithTransaction: tempState
                }
            }

            return {
                ...state
            }
        })

        .handleAction(actions.callAllTransactionDataForWidget.success, (state, action) => {

            const {widgetId, init, meta, items, hasMore} = action.payload

            const index = state.allAccountsWithTransaction.findIndex(el => el.widgetId === widgetId);
            let temp = {...state.allAccountsWithTransaction[index]}

            temp = {...temp, init: false, meta, items: init ? items : [...temp.items, ...items], hasMore }

            state.allAccountsWithTransaction[index] = temp

            return {
                ...state
            }

        })


        .handleAction(actions.fetchCallDataPerExtensionWidgetItem.request, (state, action) => {

            const {id} = action.payload
            const newStateData = {
                id,
                loading: true,
                details: {
                    sum: 0,
                    duration: 0
                },
                loaded: false,
                period: action.payload.period
            }

            const index = state.customTransactionRefreshData.findIndex(el => el.id == id)

            if (index == -1) {
                state.customTransactionRefreshData.push(newStateData)
            } else {

                const temp = state.customTransactionRefreshData[index];
                const newData = {...temp, loading: true}
                //state.customTransactionRefreshData[index] = {...temp, loading: true}
                state.customTransactionRefreshData.splice(index, 1, newData);
            }

            return {
                ...state,
                customTransactionRefreshData: state.customTransactionRefreshData
            }

        }).handleAction(actions.fetchCallDataPerExtensionWidgetItem.success, (state, action) => {
            const {id, details, period} = action.payload;
            const newStateData = {
                id,
                loading: false,
                details: {
                    ...details
                },
                loaded: true,
                period: period,
            }

            const index = state.customTransactionRefreshData.findIndex(el => el.id == id);
            if (index == -1)
            {
                state.customTransactionRefreshData.push(newStateData);
            } else {
                state.customTransactionRefreshData.splice(index, 1, newStateData);
            }

            return {
                ...state,
                customTransactionRefreshData: [...state.customTransactionRefreshData]
            };
        })
        .handleAction(actions.setCustomWidgetRange, (state, action) => {

            const index = state.customWidgetPeriods.findIndex(el => el.id === action.payload.id);
            const temp = [...state.customWidgetPeriods];

            if (index !== -1) {
                temp[index] = action.payload
            } else {
                temp.push(action.payload)
            }

            return {
                ...state,
                customWidgetPeriods: [...temp]
            }
        })
        .handleAction(actions.recalcSipCallsDurationForWidget.success, (state, action) => ({
            ...state,
            sipCallsList: action.payload
        }))
;

export default wallboardReducer;
