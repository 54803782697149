import React, {ReactElement} from 'react';
import {Divider, makeStyles, Tooltip} from "@material-ui/core";
import {RingGroupType} from "../../store/types/RingGroup";
import {Colors} from "../../styles/Colors";
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import {CallQueue} from '../../store/types/CallQueue';
import {YesNo} from '../../store/types/CallScreening';
import RingGroupCell from '../Calls/RingGroupCell';
import {getRoute, Routes} from '../../routes/routes';
import classNames from 'classnames';
import BarLoaderWrapper from "../BarLoader/BarLoaderWrapper";

export type RingGroupsSectionData = {
    i_c_ext: number,
    idle: number,
    loggedIn: number,
    onHold: number,
    onCall: number,
    queued: number,
    total: number,
};

export type RingGroupsSectionProps = {
    showTitle?: boolean,
    customThNames?: string[],
    customTopComponent?: string | ReactElement,
    showRefresh?:boolean
    useInternalLoader?:boolean,
    customPermission?: string[],
    callback?: () => void
    customContainerClass?: string
};

export const useStyles = makeStyles(() => ({

    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop:10

    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 28px)',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: '1px solid rgb(221, 225, 231)',
        padding: '12px 14px',
        height: 26,
        marginLeft: 2,
    },
    mainContainer: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        '&:last-of-type': {
            borderBottom: 'unset',
        },
        flexDirection: 'column',
    },

    label: {
        color: Colors.Text2,
        fontWeight: 500,
        fontSize: 20,
    },
    noTitleRingGroupsContainer: {
        width: '100%',

        '& .MuiTableContainer-root': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
        },

        '& .MuiTable-root thead': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
        }
    },
    ringGroupsContainer: {
        width: '100%',

        '& .MuiTableContainer-root': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: 'none'
        },

        '& .MuiTable-root thead': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        }
    },
    toolTipContainer: {
        width: 224,
        padding: '6px 12px',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    tooltipRow: {
        display: 'flex',
        width: 224,
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    tootTipLeftSpan: {
        textAlign: 'left',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '18px',
        color: Colors.White,
        overflow: 'hidden',
        width: 'fit-content',
        height: 'fit-content',
        verticalAlign: 'middle',
        opacity: 0.7,
        flexWrap: 'wrap',
        paddingTop: 10,
        paddingBottom: 10,
    },
    tootTipRightSpan: {
        textAlign: 'right',
        fontWeight: 400,
        fontSize: 14,
        color: '#F5F7F8',
        width: 'fit-content',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '40px',
        whiteSpace: 'nowrap'
    },
    toolTipDivider: {
        backgroundColor: 'rgba(245,247,248, 0.38)'
    },
    baseValue: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px'
    },
    valueGreen: {
        color: Colors.Support,
    },
    valueRed: {
        color: Colors.Error
    },
    valueBlack: {
        color: Colors.Black
    },
    valueGray: {
        color: Colors.Gray9
    },
    valueOrange: {
        color: Colors.Primary2
    },
    tableContainer: {
        '& thead :not(:first-of-type)': {
            textAlign: 'right',
        },
        '& thead tr th': {
            fontWeight: 700,
            fontSize: 14,
            lineHeight: '14px',
            color: Colors.Text3,
            padding: 14,
        },
        '& tr td:not(:first-of-type)': {
            textAlign: 'right',
            justifyContent: 'flex-end'
        },
        '& tr': {
            marginLeft: 8,
            marginRight: 8,
        }
    },
    loaderBackground: {
        position: 'absolute',
        top: -52,
        width: '100%',
        background: 'rgba(255,255,255,0.4)',
        height: 'calc(100% + 52px)'
    },
    loader: {
        top: 'calc(50% - 20px)',
        position: 'relative'
    }
}));

export function formatTime(seconds: number, t: TFunction<string>) {
    const remainingSeconds = seconds % 60;
    const minutes = Math.trunc((seconds - remainingSeconds) / 60);

    const minutesStr = String(minutes) + ' ' + t('screens:dashboard.minutesShort');
    const secondsStr = String(remainingSeconds) + ' ' + t('screens:dashboard.secondsShort');

    if (minutes > 0) {
        return `${minutesStr} ${secondsStr}`;
    }
    return `${secondsStr}`;
}

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    callQueues: CallQueue[] | undefined,
    dataForTable: RingGroupsSectionData[],
    isLoading: boolean,
    customThNames?: string[]
): Column<RingGroupType>[] => {
    return [
        {
            Header: customThNames && customThNames[0] ? customThNames[0] : t<string>('common:name'),
            accessor: 'name',
            minWidth: 156,
            Cell: function Cell(params) {
                const queue = callQueues?.find(e => e.i_c_group === params.row.original.i_c_group);

                return (
                    <BarLoaderWrapper isLoading={isLoading}>

                        <Tooltip title={
                            <div className={classes.toolTipContainer}>
                                <div className={classes.tooltipRow}>
                                    <div
                                        className={classes.tootTipLeftSpan}>{t('screens:dashboard.ringGroupNumber')}</div>
                                    <div className={classes.tootTipRightSpan}>{params.row.original.id}</div>
                                </div>
                                <Divider className={classes.toolTipDivider}/>
                                <div className={classes.tooltipRow}>
                                    <div
                                        className={classes.tootTipLeftSpan}>{t('screens:ringGroups.ringStrategy')}</div>
                                    <div
                                        className={classes.tootTipRightSpan}>{t(`enums:ringStrategy.${params.row.original.hunt_sequence}`)}</div>
                                </div>
                                {queue && (
                                    <Divider className={classes.toolTipDivider}/>
                                )}
                                {queue && (
                                    <div className={classes.tooltipRow}>
                                        <div
                                            className={classes.tootTipLeftSpan}>{t('screens:dashboard.maxQueuedCallers')}</div>
                                        <div className={classes.tootTipRightSpan} style={{
                                            lineHeight: '57px'
                                        }}>{queue?.incoming_capacity}</div>
                                    </div>
                                )}
                                {params.row.original.activity_monitoring == YesNo.Yes && (
                                    <Divider className={classes.toolTipDivider}/>
                                )}
                                {params.row.original.activity_monitoring == YesNo.Yes && (
                                    <div className={classes.tooltipRow}>
                                        <div
                                            className={classes.tootTipLeftSpan}>{t('screens:dashboard.wrapUpDuration')}</div>
                                        <div
                                            className={classes.tootTipRightSpan}>{formatTime(params.row.original.call_wrap_up_time || 0, t)}</div>
                                    </div>
                                )}
                            </div>
                        }
                                 disableHoverListener={false}
                                 id="ringGroupToolTip"
                        >
                            <div style={{
                                width: 'fit-content',
                                height: 'fit-content'
                            }}>
                                <RingGroupCell
                                    title={params.row.original.name}
                                    link={getRoute(Routes.RingGroupsDetails, {id: params.row.original.i_c_group})}
                                />
                            </div>
                        </Tooltip>
                    </BarLoaderWrapper>

                );
            },
            width: 1
        },
        {
            Header: t<string>('screens:dashboard.idleAgents'),
            accessor: 'hunt_sequence',
            Cell: function Cell(params) {
                const tableVal = dataForTable?.find(e => e.i_c_ext === params.row.original.i_c_ext);
                const value = tableVal?.idle ?? 0;
                return (
                    <BarLoaderWrapper 
                        isLoading={isLoading}
                        value={value}
                        className={classNames(classes.baseValue,
                            value > 0 ? classes.valueGreen : classes.valueRed)}
                    />
                )
            },
            minWidth: 114,
            maxWidth: 114,
        },
        {
            Header: t<string>('screens:dashboard.loggedInAgents'),
            accessor: 'assigned_callqueue',
            Cell: function Cell(params) {
                const tableVal = dataForTable?.find(e => e.i_c_ext === params.row.original.i_c_ext);
                const value = tableVal?.loggedIn ?? 0;

                return (
                    <BarLoaderWrapper
                        isLoading={isLoading}
                        value={value}
                        className={classNames(classes.baseValue,
                            value > 0 ? classes.valueBlack : classes.valueGray)}
                    />
                )

            },
            minWidth: 114,
            maxWidth: 114,
        },
        {
            Header: t<string>('screens:dashboard.agentsOnHold'),
            accessor: 'wrap_up_extend_time',
            Cell: function Cell(params) {
                const tableVal = dataForTable?.find(e => e.i_c_ext === params.row.original.i_c_ext);
                const value = tableVal?.onHold ?? 0;

                return (
                    <BarLoaderWrapper
                        isLoading={isLoading}
                        value={value}
                        className={classNames(classes.baseValue,
                            value > 0 ? classes.valueBlack : classes.valueGray)}
                    />
                )
            },
            minWidth: 114,
            maxWidth: 114,
        },
        {
            Header: t<string>('screens:dashboard.agentsOnCalls'),
            accessor: 'assigned_extensions',
            Cell: function Cell(params) {
                const tableVal = dataForTable?.find(e => e.i_c_ext === params.row.original.i_c_ext);
                const value = tableVal?.onCall ?? 0;

                return (
                    <BarLoaderWrapper
                        isLoading={isLoading}
                        value={value}
                        className={classNames(classes.baseValue,
                            value > 0 ? classes.valueBlack : classes.valueGray)}
                    />
                )
            },
            minWidth: 114,
            maxWidth: 114,
        },
        {
            Header: t<string>('screens:dashboard.queuedCalls'),
            accessor: 'hunt_keep_original_cli',
            Cell: function Cell(params) {
                const tableVal = dataForTable?.find(e => e.i_c_ext === params.row.original.i_c_ext);
                const value = tableVal?.queued ?? 0;

                return (
                    <BarLoaderWrapper isLoading={isLoading}
                        value={value}
                        className={classNames(classes.baseValue,
                            value > 0 ? classes.valueOrange : classes.valueGray)}
                    />
                )
            },
            minWidth: 114,
            maxWidth: 114,
        },
        {
            Header: t<string>('screens:dashboard.totalAgents'),
            accessor: 'i_c_ext',
            Cell: function Cell(params) {
                const tableVal = dataForTable?.find(e => e.i_c_ext === params.row.original.i_c_ext);
                const value = tableVal?.total ?? 0;

                return (
                    <BarLoaderWrapper
                        isLoading={isLoading}
                        value={value}
                        className={classNames(classes.baseValue,
                            value > 0 ? classes.valueBlack : classes.valueGray)}
                    />
                )
            },
            minWidth: 114,
            maxWidth: 114,
        },
    ];
};

export const fakeSingleRow:RingGroupType = {
    id: '1',
    name: 'fake',
    hunt_sequence: '',
    i_c_group:1
}
