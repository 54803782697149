import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import {PortalPermission} from "../../store/types/Permission";
import {CounterInterface, CustomerAgreementCondition} from "../../store/types/CustomerAgreementCondition";
import {CustomerOfficeType} from "../../store/types/CustomerInfo";
import {APIErrorInterface} from '../../store/types';

export const useStyles = makeStyles({
    container: {
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        flex: 1,
    },
    tagContainer: {
        position: "fixed",
        bottom: 37,
        paddingLeft: 20
    },
    scrollableContainer: {
        display: 'flex',
        minHeight: '100vh',
        flex: 1,
        backgroundColor: Colors.White
    },
    containerPadding: {
        background: Colors.White,
        padding: '40px 24px 40px 24px',
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(82% - 120px)',
        maxWidth: 860,

        '@media (min-width: 1520px)': {
            padding: '40px 90px 40px 90px',
            width: 'calc(82% - 250px)',
            maxWidth: 1000,
        },

        '@media (min-width: 1600px)': {
            padding: '40px 90px 40px 90px',
            width: 'calc(82% - 250px)',
            maxWidth: 840,
        },

        '@media (min-width: 1680px)': {
            padding: '40px 90px 40px 90px',
            width: 'calc(82% - 250px)',
            maxWidth: 900,
        },
    },
    mainHeader: {
        fontSize: 32,
        color: Colors.Gray7,
        fontWeight: 500,
        paddingBottom: 32,
    },
    sectionHeader: {
        fontSize: 18,
        color: Colors.Gray7,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        marginBottom: 32,

        '& > *': {
            marginRight: 32,
        },
        '& :last-child': {
            marginRight: 0,
        },
    },
    dot: {
        height: 9,
        width: 9,
        borderRadius: '50%',
        backgroundColor: Colors.Support,
    },
    redDot: {
        backgroundColor: Colors.Error,
    },
    orangeDot: {
        backgroundColor: Colors.Primary,
    },
    emptyCard: {
        flex: 1,
    },
    loaderContainer: {
        height: 148,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    doughnutsContainer: {
        display: 'flex',
        margin: -12,
        marginBottom: 12,
    },
    rightSidePanel: {
        backgroundColor: Colors.LightGray,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '110px 20px',
        height: '100%',
        position: 'absolute',
        right: 0,
        width: '18%',

        '@media (min-width: 1600px)': {
            padding: '110px 64px',
        }
    },
    rightSidePanelWithEmptyContext: {
        backgroundColor: Colors.White,
        padding: 0
    },
    rightSidePanelContent: {},
    hiddenRightSidePanelContent: {
        display: 'none'
    },
    panelHeader: {
        fontWeight: 500,
        color: Colors.Text,
        fontSize: 20,
        marginLeft: 18,
        marginBottom: 20,
        display: 'flex',
    },
    bottomMargin: {
        marginBottom: 24
    }
});

export type DashboardViewData = {
    registeredExtensions: number | undefined,
    unregisteredExtensions: number | undefined,
    activeCalls: number | undefined,
    callsFromLastDays: { date: string; value: number }[] | undefined,
    inUseDidNumbers: number | undefined,
    notInUseDidNumbers: number | undefined,
    inUseDevices: number | undefined,
    notInUseDevices: number | undefined,
    permissions: PortalPermission | undefined,
    mainProducts: CustomerAgreementCondition[] | undefined,
    addonProducts: CustomerAgreementCondition[] | undefined,
    outDateFormat: string | undefined,
    i_office_type: CustomerOfficeType,
    accessDeniedToDidNumbers: boolean | undefined,
    sipCallsApiError: APIErrorInterface | undefined,
    bundles: CounterInterface[] | undefined
};