export enum PasswordComplexityRules {
    LatinLettersCanBeUsed = 'ca',
    LatinLettersMustBeUsed = 'cr',
    DigitsCanBeUsed = 'na',
    DigitsMustBeUsed = 'nr',
    LowercaseLettersCanBeUsed = 'la',
    LowercaseLettersMustBeUsed = 'lr',
    UppercaseLettersCanBeUsed = 'ua',
    UppercaseLettersMustBeUsed = 'ur',
    SpecialCharactersCanBeUsed = 'sa',
    SpecialCharactersMustBeUsed = 'sr',
}

export interface PasswordRules {
    PasswordComplexityRules: PasswordComplexityRules[];
    PasswordMinLength: number;
}

export interface AudioFilesConfig {
    ResponseMessageKeepDays: number;
}

export interface ConfigData {
    Accounts: {
        CreditAccMinServicePasswdLength: string;
    };
    Web: PasswordRules;
    MediaServerDomain: string;
    Global?: {
        Time_Zone: string;
        Locale_Language: string;
    };
    AudioFiles: AudioFilesConfig;
    CDR?: {
        Enable_Call_Recording?: number,
        Keep_month?: number,
    }
}