import {ActionType} from "typesafe-actions";
import {actions} from "../..";
import {APIErrorInterface, ReduxState} from "../../types";
import JSONFormData from "../../../utils/JSONFormData";
import {Customer} from "../../../services/endpoints";
import {api} from "../../services/axios";
import {put, select, takeLatest} from 'redux-saga/effects';
import {AxiosResponse} from "axios";
import {showToast} from "../../../utils/showToast";
import {CallParkingSlotResponsePayload} from "../../actions/callParkingSlots/payloads";

export function* getCallParkingSlotsList(
    action: ActionType<typeof actions.getCallParkingSlotsList.request>) {
        
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            ...action.payload,
            get_total: 1
        });

        const res: AxiosResponse<CallParkingSlotResponsePayload> = yield api.post(Customer.GetCallParkingSlots, body);

        yield put(
            actions.getCallParkingSlotsList.success(res.data),
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data as APIErrorInterface;
        showToast(error?.faultstring);
        yield put(actions.getCallParkingSlotsList.failure(error));
    }
}

export function* deleteCallParkingSlot(
    action: ActionType<typeof actions.deleteCallParkingSlot.request>) {
        
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        yield api.post(Customer.DeleteCallParkingSlot, body);
        
        action.payload.callback?.();

        yield put(
            actions.deleteCallParkingSlot.success(),
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data as APIErrorInterface;
        showToast(error?.faultstring);
        yield put(actions.deleteCallParkingSlot.failure(error));
    }
}

export function* addCallParkingSlot(
    action: ActionType<typeof actions.addCallParkingSlot.request>) {
        
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        yield api.post(Customer.AddCallParkingSlot, body);
        
        action.payload.callback?.();

        yield put(
            actions.addCallParkingSlot.success(),
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data as APIErrorInterface;
        yield put(actions.addCallParkingSlot.failure(error));
    }
}

export function* editCallParkingSlot(
    action: ActionType<typeof actions.editCallParkingSlot.request>) {
        
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        yield api.post(Customer.UpdateCallParkingSlot, body);
        
        action.payload.callback?.();

        yield put(
            actions.editCallParkingSlot.success(),
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data as APIErrorInterface;
        yield put(actions.editCallParkingSlot.failure(error));
    }
}

export const callParkingSlotsSaga = [
    takeLatest(actions.getCallParkingSlotsList.request, getCallParkingSlotsList),
    takeLatest(actions.deleteCallParkingSlot.request, deleteCallParkingSlot),
    takeLatest(actions.addCallParkingSlot.request, addCallParkingSlot),
    takeLatest(actions.editCallParkingSlot.request, editCallParkingSlot),
];
