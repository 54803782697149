import React from "react";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {IconButton, Menu} from "@material-ui/core";
import {MoreVert} from "@material-ui/icons";
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        marginBottom: 10
    },
    moreOptionsButton: {
        '& svg.MuiSvgIcon-root > path': {
            fill: Colors.Gray5,
            fillOpacity: 1,
        },
        '&:hover': {
            backgroundColor: Colors.Gray11,
        },
        padding: 8,
        marginLeft: 14
    },
}));

const MoreMenu: React.FC<{ hideMoreOptionsButton: boolean }> = ({hideMoreOptionsButton, children}) => {

    const [menuItemRef, setMenuItemRef] = React.useState<null | HTMLElement>(null);
    const open = Boolean(menuItemRef);
    const classes = useStyles();

    const handleMenuOpenClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuItemRef(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuItemRef(null);
    };

    const {t} = useTranslation()

    return !hideMoreOptionsButton ? (
        <div className={classes.container}>
            <CustomizedTooltip
                title={t('tooltips:devices.moreActions')}
                copy={false}
            >
                <IconButton
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup
                    data-testid="mevices-header-dropdownmenu"
                    data-qa="mevices-header-dropdownmenu"
                    className={classes.moreOptionsButton}
                    onClick={handleMenuOpenClick}
                >
                    <MoreVert htmlColor={Colors.Gray5}/>
                </IconButton>
            </CustomizedTooltip>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={menuItemRef}
                getContentAnchorEl={null}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: "auto",
                        marginTop: 4
                    },
                }}
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                transformOrigin={{vertical: "top", horizontal: "left"}}
            >
                <div onClick={handleMenuClose}>
                    {children}
                </div>

            </Menu>
        </div>) : <></>


}

export default MoreMenu;