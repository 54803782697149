import React, {useMemo} from "react";
import DoughnutSection from "../Dashboard/DoughnutSection";
import {ReactComponent as Person} from '../../assets/person_3.svg';
import {ReactComponent as Phone} from '../../assets/phone2.svg';
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import CallStatisticTooltip from "./CallStatisticTooltip";
import {ActiveCall} from "../../store/types/CallHistory";
import {buildCallsArray} from "./CallStatiscics.utils";
import {ChartLegendItem} from "../../views/Wallboard/Widgets/WidgetLegendItem";
import {SipCall, SipCallState, SipCallType} from "../../store/actions/ringgroups/payloads";
import {ExtensionType} from "../../store/types/Extension";
import {useSelector} from "react-redux";
import {APIErrorInterface, ReduxState} from "../../store/types";
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import {Permission} from "../../store/types/Permission";
import Config from '../../config.json';
import { ENABLE_NOTIFICATIONS_ACCESS_DENIED, GET_SIP_CALLS_ACCESS_DENIED } from "../../store/sagas/ringgroups/saga";
import AccessDenied from "../PermissionProvider/AccessDenied";

const CallStatistics: React.VFC<{ calls: ActiveCall[] | SipCall[], isLoading?:boolean }> = ({
    calls,
    isLoading
}) => {
    const {t} = useTranslation();
    const cutout = '85%';

    const extensionsList: ExtensionType[] = useSelector((state: ReduxState) => state.calls?.callsDoughnutExtensionsList ?? []);
    const sipCallsList: SipCall[] = useSelector((state: ReduxState) => state.ringgroups?.sipCallsList ?? []);
    const callsData = buildCallsArray(calls, ['forwarded']);
    
    const sipCallsApiError: APIErrorInterface | undefined = useSelector((state: ReduxState) => state.ringgroups?.sipCallsApiError);
    
    const filteredExtensions = useMemo(() => {
        return extensionsList ?? [];
    }, [extensionsList]);

    const filteredExtensionsInCall = useMemo(() => {
        const extIds: string[] = [];
        for (const e of sipCallsList ?? []) {
            if (e.callee?.extension_id && filteredExtensions.findIndex(c => c.extension_id === e.callee.extension_id) !== -1) {
                extIds.push(e.callee.extension_id);
            }
            if ((e.caller?.extension_id && filteredExtensions.findIndex(c => c.extension_id === e.caller.extension_id) !== -1)) {
                extIds.push(e.caller.extension_id);
            }
        }
        return filteredExtensions?.filter(e => !!extIds?.find(k => k === e.extension_id)) ?? [];
    }, [filteredExtensions, sipCallsList]);

    const filteredExtensionsNotInCall = useMemo(() => {
        return filteredExtensions?.filter(e => !filteredExtensionsInCall?.find(k => k.extension_id === e.extension_id)) ?? [];
    }, [filteredExtensionsInCall, filteredExtensions]);

    const unregisteredExtensions = useMemo(() => {
        return filteredExtensionsNotInCall?.filter(e => e.sip_status === 0);
    }, [filteredExtensionsNotInCall]);

    const unregisteredCount = useMemo(() => {
        return unregisteredExtensions?.length || 0;
    }, [unregisteredExtensions]);


    const callsFilteredByExtensions = useMemo(() => {
        return sipCallsList
                ?.filter(e =>
                    (e.callee?.extension_id && filteredExtensionsInCall.findIndex(c => c.extension_id === e.callee.extension_id) !== -1) || // && c.sip_status !== 0
                    (e.caller?.extension_id && filteredExtensionsInCall.findIndex(c => c.extension_id === e.caller.extension_id) !== -1) // && c.sip_status !== 0
                )
            || [];
    }, [filteredExtensionsInCall, sipCallsList]);

    const onCallExtensions = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => (e.state === SipCallState.Connected)
                || (e.state === SipCallState.Trying && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Ringing && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Queued && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Dequeued)
            ) ?? [];

        const extIds: string[] = [];
        for (const e of calls) {
            if (e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if (e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }
        return filteredExtensionsInCall.filter(e => !!extIds.find(d => d === e.extension_id)) ?? [];
    }, [callsFilteredByExtensions, filteredExtensionsInCall]);

    const onCall = useMemo(() => {
        return onCallExtensions?.length || 0;
    }, [onCallExtensions]);


    /*
        state:holding
        state:held
    */
    const onHold = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => e.state === SipCallState.Holding || e.state === SipCallState.Held);
        const ids = calls
                ?.map(e => (e.tracking_id ?? e.callee?.account_id ?? e.callee?.account_id ?? ''))
            || [];
        return [...new Set(ids)].length;
    }, [callsFilteredByExtensions]);

    const onHoldExtensions = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => e.state === SipCallState.Holding || e.state === SipCallState.Held);

        const extIds: string[] = [];
        for (const e of calls) {
            if (e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if (e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }

        return filteredExtensionsInCall
                ?.filter(e => !!extIds.find(d => d === e.extension_id))
                ?.filter(e => !(!!onCallExtensions.find(d => d === e)))
            ?? [];
    }, [callsFilteredByExtensions, filteredExtensionsInCall, onCallExtensions]);

    const ringingExtensions = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => (e.state === SipCallState.Ringing && e.type === SipCallType.Incoming)
                || (e.state === SipCallState.Trying && e.type === SipCallType.Incoming)
            );

        const extIds: string[] = [];
        for (const e of calls) {
            if (e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if (e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }

        return filteredExtensionsInCall
                ?.filter(e => !!extIds.find(d => d === e.extension_id))
                ?.filter(e => !(!!onCallExtensions.find(d => d === e)))
                ?.filter(e => !(!!onHoldExtensions.find(d => d === e)))
            ?? [];
    }, [callsFilteredByExtensions, filteredExtensionsInCall, onCallExtensions, onHoldExtensions]);

    const ringing = useMemo(() => {
        return ringingExtensions?.length || 0;
    }, [ringingExtensions]);


    const idleExtensions = useMemo(() => {
        return filteredExtensionsNotInCall
                ?.filter(e => e.sip_status === 1)
            ?? [];
    }, [filteredExtensionsNotInCall]);

    const idleCount = useMemo(() => {
        return idleExtensions?.length || 0;
    }, [idleExtensions]);


    const chartData = useMemo(() => {
        const retArray = [
            {
                label: t('screens:wallboard.onCallCount'),
                value: onCall,
                color: Colors.Error,
                testId: 'on-call-label'
            } as ChartLegendItem,
            {
                label: t('screens:wallboard.onHoldCount'),
                value: onHold,
                color: Colors.Error,
                testId: 'on-hold-label'
            } as ChartLegendItem,
            {
                label: t('screens:wallboard.ringingCount'),
                value: ringing,
                color: Colors.Support,
                testId: 'ringing-label'
            } as ChartLegendItem
        ];

        retArray.push(
            {
                label: t('screens:wallboard.idleCounts'),
                value: idleCount,
                color: Colors.Support,
                testId: 'idle-label'
            } as ChartLegendItem);

        retArray.push(
            {
                label: t('screens:wallboard.unregisteredCount'),
                value: unregisteredCount,
                color: Colors.Primary,
                testId: 'unregistered-label'
            } as ChartLegendItem
        );

        return retArray;
    }, [onCall, onHold, ringing, idleCount, unregisteredCount]);

    const sum = useMemo(() => {
        let s = 0;
        chartData.forEach(item => {s = s + item.value});
        return s;
    }, [chartData]);

    const hasWebSocket = useMemo(() => {
        if(!Config.WEBSOCKET_SERVER_URL?.length) {
            return false;
        }
        return true;
    }, [Config]);

    if (sipCallsApiError?.faultcode === ENABLE_NOTIFICATIONS_ACCESS_DENIED
        || sipCallsApiError?.faultcode === GET_SIP_CALLS_ACCESS_DENIED) {
        return (<></>);
    }
    
    return <>
        <PermissionProvider permission={Permission.CloudPBX.Extensions.value}>
            <Tooltip
                title={
                    <CallStatisticTooltip
                        title={t('tooltips:calls.availableExtensions')}
                        items={chartData}
                        summary={sum || 0}
                    />
                }
                copy={false}
                disableHoverListener={sum == 0}
            >
                <div>
                    <DoughnutSection
                        hideLabel={true}
                        hideLegend={true}
                        //@ts-ignore
                        cutout={cutout}
                        amountOfSectionsInParentContainer={1}
                        data={chartData}
                        isLoading={
                            isLoading || false
                        }
                        icon={<Person/>}
                        hideLoadingLabelOnLoading={true}
                        inLine={true}
                    />
                </div>
            </Tooltip>
        </PermissionProvider>

        <Tooltip
            title={<CallStatisticTooltip
                title={t('screens:calls.currentCalls')}
                items={callsData.items}
                summary={callsData.total}
            />
            }
            copy={false}
            disableHoverListener={callsData.total == 0}
        >
            <div>
                <DoughnutSection
                    hideLabel={true}
                    hideLegend={true}
                    //@ts-ignore
                    cutout={cutout}
                    amountOfSectionsInParentContainer={1}
                    data={callsData.items}
                    isLoading={
                        isLoading || false
                    }
                    icon={<Phone/>}
                    hideLoadingLabelOnLoading={true}
                    inLine={false}
                    doNotAnimateValue={hasWebSocket}
                />
            </div>
        </Tooltip>

        <span style={{marginRight: 34}}/>
    </>
}

export default CallStatistics