import RingGroupsSection from "../Dashboard/RingGroupsSection";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Permission} from "../../store/types/Permission";
import {SearchCallback} from "../../views/CallHistory/CallHistory.utils";
import { useMemo } from "react";
import Config from '../../config.json';
import { useSelector } from "react-redux";
import { ReduxState, APIErrorInterface } from "../../store/types";
import AccessDenied from "../PermissionProvider/AccessDenied";
import { ENABLE_NOTIFICATIONS_ACCESS_DENIED, GET_SIP_CALLS_ACCESS_DENIED } from "../../store/sagas/ringgroups/saga";

const RingGroupsTab: React.VFC<SearchCallback>  = ({callback}) => {

    const {t} = useTranslation();

    const sipCallsApiError = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.ringgroups?.sipCallsApiError
    );
    
    const hasWebSocket = useMemo(() => {
        if(!Config.WEBSOCKET_SERVER_URL?.length) {
            return false;
        }
        return true;
    }, [Config]);

    if (sipCallsApiError?.faultcode === ENABLE_NOTIFICATIONS_ACCESS_DENIED
        || sipCallsApiError?.faultcode === GET_SIP_CALLS_ACCESS_DENIED) {
        return <AccessDenied subPageMode />;
    }
    
    return(
        <div style={{maxWidth:1024}}>
                <RingGroupsSection
                    showTitle={false}
                    customThNames={[t('screens:ringGroups.ringGroups')]}
                    showRefresh={!hasWebSocket}
                    useInternalLoader={false}
                    customPermission={Permission.SupervisorPanel.RingGroups.value}
                    callback={callback}
                />
        </div>

    )

}

export default RingGroupsTab