import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TextField from '../TextField/TextField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import {IconButton} from '@material-ui/core';
import {APIErrorInterface, ReduxState} from '../../store/types';
import {useSelector} from 'react-redux';
import i18n from '../../services/i18n';
import {useFormikContext} from 'formik';
import * as Yup from 'yup';
import {ChangeDirtyStateDetailsTab} from '../../views/Extensions/ExtensionDetails/utils';
import {getConfigFromPasswordRulesOrDefault} from '../../utils/passwordGenerator';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField';
import CopyContentIcon from "../CopyContentIcon/CopyContentIcon";
import {nameMaxLengthError, requiredFieldError} from "../PortalUsers/utils";
import {PasswordFaultCode} from "../../store/types/Extension";
import CustomizedTooltip from '../Tooltip/Tooltip';

const useStyle = makeStyles(() => ({
    root: {},
    itemsContainer: {
        marginTop: -10,
        '& .MuiFormControl-root': {
            width: '100%',
            maxWidth: 440,
            marginBottom: 16,
        },
        '& .MuiGrid-root': {
            maxWidth: 440,
            margin: '16px',
            padding: 0,
        },
    },
    iconColors: {
        backgroundColor: Colors.White,
        '& .MuiSvgIcon-root': {
            '& path': {
                fill: `${Colors.PlaceHolder} !important`,
                fillOpacity: 1,
            },
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },

    controls: {
        '& .MuiFormControl-root:last-child': {
            marginBottom: 16,
        },
    },

    tooltip: {
        overflowWrap: 'anywhere',
    },
}));

export interface SipFormType {
    registrationName: string;
    servicePassword: string;
    userAgent: string;
    iAccount: number;
    sipInfoErrors: APIErrorInterface | undefined;
    savingSipInfo: boolean | undefined;
    sipContact: string;
}

export const sipDefaultValues: SipFormType = {
    registrationName: '',
    servicePassword: '',
    userAgent: '',
    iAccount: 0,
    sipInfoErrors: undefined,
    savingSipInfo: false,
    sipContact: '',
};


export const SipValidationSchema = ((portalPasswordLength: number) => Yup.object().shape({
    servicePassword: Yup.string()
        .min(portalPasswordLength, i18n.t(`errors:extensions.sipPasswordWithLength`, {
            value: portalPasswordLength,
        }))
        .test(
            'passLength',
            i18n.t(`screens:portalUsers.portalPassword`, {
                value: portalPasswordLength,
            }),
            function (value) {
                if (
                    value !== undefined &&
                    portalPasswordLength
                ) {
                    return (
                        value.length >=
                        Number(
                            portalPasswordLength
                        )
                    );
                }

                return true;
            },
        )
        .test(
            'passChars',
            i18n.t(`errors:sipTrunks.whiteSpaceAdded`),
            function (value) {
                const test = value?.indexOf(' ')
                return test == undefined || test == -1
            }
        )
        .max(32, nameMaxLengthError)
        .required(requiredFieldError),
}));


interface SipProps {
    register: boolean;
    apiErrors?: APIErrorInterface;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
}

const Sip: React.FC<SipProps> = (
    {
        apiErrors,
        register = true,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
    }) => {
    const classes = useStyle();
    const {t} = useTranslation();

    const textInputContactDetailsRef = useRef<HTMLInputElement>(null);
    const [copyRegNameVisible, setCopyRegNameVisible] = useState(false);
    const [copyPasswordNameVisible, setCopyPasswordNameVisible] = useState(
        false,
    );

    const configData = useSelector(
        (state: ReduxState) => state.generic.configData,
    );

    const handleChangeVisible = () => {
        setCopyRegNameVisible((prevState) => !prevState);
    };
    const handlePasswordCopyVisible = () => {
        setCopyPasswordNameVisible((prevState) => !prevState);
    };

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        handleSubmit,
        isValid,
        dirty
    } = useFormikContext<SipFormType>();

    const notSetText = t('common:notSet');
    const setNewPassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setFieldValue('servicePassword', e.target.value.replaceAll(' ', ''))
    };

    useEffect(() => {
        handleDirtyChange?.('device', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetIsValidFunc?.('device', () => isValid);
    }, [isValid]);

    useEffect(() => {
        handleSetSubmitFunc?.('device', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        if (apiErrors?.faultcode == PasswordFaultCode.InvalidChars) {
            setFieldError(
                'servicePassword',
                t('errors:sipTrunks.invalidChars'),
            );
        }
    }, [apiErrors]);

    return (
        <div data-testid={'sip-info-details'} data-qa={'sip-info-details'}>
            <Grid container spacing={3} className={classes.itemsContainer}>
                <Grid item xs={6} className={classes.controls}>
                    <div>
                        <TextField
                            onMouseEnter={handleChangeVisible}
                            onMouseLeave={handleChangeVisible}
                            id="registrationName"
                            label={t('screens:extensions.registrationName')}
                            onChange={handleChange}
                            value={values.registrationName}
                            icon={
                                <>
                                    <div
                                        style={{
                                            visibility: copyRegNameVisible
                                                ? 'visible'
                                                : 'hidden',
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            className={classes.visibilityIcon}
                                            data-testid="registration-name-visibility-button"
                                            data-qa="registration-name-visibility-button"
                                        >
                                            <CopyContentIcon
                                                copyContent={
                                                    values.registrationName
                                                }
                                                tooltipMsg={t(
                                                    'tooltips:extensions.copyRegistrationName',
                                                )}
                                            />
                                        </IconButton>
                                    </div>
                                    <IconWithTooltip
                                        dataQa="sip-registration-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:extensions.registrationName',
                                        )}
                                    />
                                </>
                            }
                            iconPosition="end"
                            dataQa="sip-registration-name-input"
                            readOnly
                            noTooltip
                        />
                    </div>
                    <PasswordTextField
                        onMouseEnter={handlePasswordCopyVisible}
                        onMouseLeave={handlePasswordCopyVisible}
                        id="servicePassword"
                        label={t('screens:extensions.servicePassword')}
                        value={values.servicePassword}
                        setFieldValue={setFieldValue}
                        onChange={setNewPassword}
                        dataQa="sip-service-password-input"
                        setFieldError={setFieldError}
                        error={
                            errors.servicePassword
                        }
                        isRequired={true}
                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                            configData
                        )}
                        maxLength={32}
                        icon={
                            <>
                                <div
                                    style={{
                                        visibility: copyPasswordNameVisible
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        className={classes.visibilityIcon}
                                        data-testid="service-password-visibility-button"
                                        data-qa="service-password-visibility-button"
                                    >
                                        <CopyContentIcon
                                            copyContent={values.servicePassword}
                                            tooltipMsg={t(
                                                'tooltips:extensions.copyServicePassword',
                                            )}
                                        />
                                    </IconButton>
                                </div>
                            </>
                        }
                    />
                </Grid>

                {register && (
                    <Grid item xs={6} className={classes.controls}>
                        <TextField
                            id="userAgent"
                            label={t('screens:extensions.userAgent')}
                            onChange={handleChange}
                            value={values.userAgent || notSetText}
                            dataQa="sip-user-agent-input"
                            readOnly
                        />
                        <div>
                            <CustomizedTooltip
                                title={values.sipContact}
                                interactive
                                copy
                                classes={{text: classes.tooltip}}
                            >
                                <div>
                                    <TextField
                                        id="contactDetails"
                                        inputRef={textInputContactDetailsRef}
                                        label={t(
                                            'screens:extensions.contactDetails',
                                        )}
                                        onChange={handleChange}
                                        value={values.sipContact}
                                        dataQa="sip-user-contact-details"
                                        readOnly
                                        noTooltip
                                    />
                                </div>
                            </CustomizedTooltip>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default Sip;
