import React from 'react'
import {CounterInterface, ThresholdType} from "../../../store/types/CustomerAgreementCondition";
import {convertToMinutesAndSeconds} from "./BundleRow.utils";
import {roundUp} from "../../../utils/roundUp";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../../styles/Colors";

export type ResourceMeterProps = {
    product: CounterInterface;
};

const useStyles = makeStyles(() => ({
    resourceMeterContainer: {
        display: 'grid',
        columnGap: 6,
        gridTemplateColumns: 'auto auto auto',
        '& div': {
            display: 'inline-grid'
        }
    },
    usedQuantity: {
        color: Colors.Text,
        fontWeight: 400,
    },
    maxQuantity: {
        color: Colors.Gray12,
        fontWeight: 400
    },
}))

const id1 = 'product-row-used-quantity'
const id2 = 'product-row-max-quantity'

const ResourceMeter: React.VFC<ResourceMeterProps> = ({product}) => {

    const {t} = useTranslation()
    const classes = useStyles()

    const title = t('enums:standardServiceName.' + product.i_service, product.service_name)
    const unitsShort = t(`units:short.${product.unit}`, product.unit, title)

    const value1 = product.threshold_type === ThresholdType.Time ?
        convertToMinutesAndSeconds(Number(product.used), product.unit, product.service_ratio, unitsShort)
        : `${product.used} ${unitsShort}`

    const value2 = product.threshold === 'N/A'
        ? t('screens:dashboard.unlimited')
        : roundUp(Number(product.threshold), 2)

    return (
        <div className={classes.resourceMeterContainer}>
            <div
                className={classes.usedQuantity}
                data-testid={id1}
                data-qa={id1}
            >{value1}
            </div>
            <div className={classes.maxQuantity}>/</div>
            <div
                className={classes.maxQuantity}
                data-testid={id2}
                data-qa={id2}
            >{value2}</div>
        </div>
    )
}

export default ResourceMeter