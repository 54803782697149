import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
import {useStyles} from '../productRowUtils';
import TwoLineCell from "../../Calls/TwoLineCell";
import CustomizedTooltip from "../../Tooltip/Tooltip";
import BundleRowTooltip from "./BundleRowTooltip";
import {BundleRowProps, getLabel} from "./BundleRow.utils";
import ResourceMeter from "./ResourceMeter";

export const testId = 'bundle-row-data-wrapper'

const BundleRow: React.VFC<BundleRowProps> = ({product, lastRow}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const tempUsed = Number(product.used)
    const tempThreshold = Number(product.threshold)
    const percentValue = tempUsed === tempThreshold ? 100 : (100 * tempUsed) / tempThreshold
    const title = t('enums:standardServiceName.' + product.i_service, product.service_name)

    const isNotActive = useMemo(() =>
            product.discount_flag === 'U' && !Number(product.threshold) && !Number(product.remaining)
        , [product])

    let barColorStyle: string
    if (product.threshold === 'N/A') {
        barColorStyle = classes.unlimitedProgressBar
    } else if (Number(product.used) < Number(product.threshold)
    ) {
        barColorStyle = classes.bundleProgressBar
    } else if (Number(product.used) === 0 || isNotActive) {
        barColorStyle = classes.unlimitedProgressBar

    } else if (Number(product.used) == Number(product.threshold)) {
        barColorStyle = classes.fullProgressBar
    } else {
        barColorStyle = classes.fullProgressBar
    }

    const valueForProgress = isNotActive ? 0 : percentValue > 100 ? 100 : percentValue

    return (
        <CustomizedTooltip
            title={<BundleRowTooltip product={product}/>}
            copy={false}
            classes={{tooltipContainer: classes.tooltipContainer}}
            disableHoverListener={isNotActive}
            above={true}
            dataQa={'bundle-row-tooltip-wrapper'}
        >
            <div className={lastRow ? classes.mainContainerLast : classes.mainContainer}
                 data-qa={testId}
                 data-testid={testId}
            >
                <div className={classes.firstColumn}>
                    <TwoLineCell
                        headerText={title}
                        descriptionText={product.dg_name}
                        headerClassName={classes.smallMargin}
                    />
                </div>

                <div className={classes.firstColumn}>
                    <LinearProgress
                        className={classNames(
                            classes.progressBar,
                            barColorStyle
                        )}
                        variant="determinate"
                        value={valueForProgress}
                    />
                </div>

                <div className={classes.secondColumn}>

                    {!isNotActive ? <ResourceMeter product={product}/> : (
                        <span>{t('screens:dashboard.notActiveYet')}</span>)
                    }

                </div>
                <div className={classes.thirdColumn}>
                    <div className={classes.mainProductLabel}>
                        <span>{getLabel(product)}</span>
                    </div>
                </div>
            </div>

        </CustomizedTooltip>

    );
};

export default BundleRow;
