import * as actions from '../../actions';

import {put, select, takeLatest} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import account from '../../services/account';
import {AxiosResponse} from 'axios';
import {showToast} from '../../../utils/showToast';
import i18n from '../../../services/i18n';

export function* postServicePassword(
    action: ActionType<typeof actions.postServicePassword.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const response: AxiosResponse<{
            i_account: number;
        }> = yield account.postUpdate(session_id, csrf_token, action.payload);

        const { i_account } = response.data;
        yield put(
            actions.postServicePassword.success({
                i_account,
                account_inf: action.payload,
            }),
        );

        action.payload.callback?.();
        showToast(i18n.t<string>('screens:extensions.passwordChanged'));
    } catch (e) {
        //@ts-ignore
        showToast(e.response?.data.faultstring);
        //@ts-ignore
        yield put(actions.postServicePassword.failure(e));
    }
}

export const passwordServiceSaga = [
    takeLatest(actions.postServicePassword.request, postServicePassword),
];
