import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {callQueueFormDefaultValues} from '../components/Forms/RingGroups/CallQueueForm';
import {ringGroupGeneralFormDefaultValues} from '../components/Forms/RingGroups/GeneralForm';
import {groupMembersDefaultValues} from '../components/Forms/RingGroups/GroupMembersForm';
import {DispatchType, GroupMemberType, RingGroupMemberFormProps,} from '../components/RingGroups/groupDialogUtils';
import {ReduxState} from '../store/types';
import {
    AssignedExtension,
    CallerID,
    RingGroupType,
    RingStrategy,
} from '../store/types/RingGroup';

import {EditRingGroupForm} from '../views/RingGroups/RingGroupDetails.utils';
import {getCustomDelayExpireValue, getDispatchType} from "../utils/extensions/AddExtensionToRingGroup";
import {YesNo} from '../store/types/CallScreening';
import {CallQueueActionsType} from '../store/types/CallQueue';
import {CallQueuePromptActionType} from '../components/Forms/RingGroups/callQueueFormUtils';

const defaultValues = {
    ...ringGroupGeneralFormDefaultValues,
    ...groupMembersDefaultValues,
    ...callQueueFormDefaultValues,
};

export const mapCallQueuePromptActionType = (value: CallQueueActionsType) => {
    return {
        prompt: value.i_prompt,
        promptAction: value.prompt_action,
        promptName: value.prompt_name,
        waitConfirmation: value.wait_confirmation === YesNo.Yes,
        action: value.i_callqueue_action,
        redirectToEntity: value.redirect_to_i_entity
    } as CallQueuePromptActionType;
};

const mapMembers = (v: AssignedExtension) => {
    return {
        id: v.id,
        ignoreFollowMeVoiceMail: v.huntstop === YesNo.Yes,
        setAsPrimary: v.primary_group === YesNo.Yes,
        dispatchType:
            v.hunt_active === YesNo.Yes
                ? getDispatchType(v.hunt_delay, v.hunt_expire)
                : DispatchType.Disabled,
        customWaitsFor: getCustomDelayExpireValue(getDispatchType(v.hunt_delay,v.hunt_expire), v.hunt_delay) ,
        customThenRingsFor: getCustomDelayExpireValue(getDispatchType(v.hunt_delay,v.hunt_expire), v.hunt_expire, 30),
        groupMemberType:
            v.type === 'Account'
                ? GroupMemberType.Extension
                : GroupMemberType.RingGroup,
        ringGroup:
            v.type === 'Account'
                ? undefined
                : {
                    i_c_group: v.i_c_group,
                    name: v.name,
                    id: v.id,
                    i_c_ext: v.i_c_ext,
                },
        extension:
            v.type === 'Account'
                ? {
                    i_c_group: v.i_c_group,
                    name: v.name,
                    id: v.id,
                    i_c_ext: v.i_c_ext,
                }
                : undefined,
        supervisionAllowed: v.supervision_allowed,
        member: {
            i_c_ext: undefined,
            i_c_group: undefined,
            id: undefined,
            name: undefined,
        },
        allowGroupPaging: v.paging_allowed === YesNo.Yes
    } as RingGroupMemberFormProps;
} 

function secondsToMinutesAndSeconds(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    return `${formattedMinutes}:${formattedSeconds}`;
}

export const useEditRingGroupFormData = () => {
    const details = useSelector<ReduxState, RingGroupType | undefined>(
        (state) => state.ringgroups.ringGroupDetails,
    );

    const initialValues = useMemo((): EditRingGroupForm => {
        if (!details) {
            return defaultValues;
        }

        const activeMonitoring = details.wrapUp?.activity_monitoring && details.wrapUp?.activity_monitoring == YesNo.Yes || false;
        const callWrapUpTimeVal = activeMonitoring ? details.wrapUp?.call_wrap_up_time : undefined;
        const callWrapUpExtTimeVal = activeMonitoring ? details.wrapUp?.wrap_up_extend_time : undefined;

        return {
            name: details.name,
            number: details.id,
            callerId: details.hunt_keep_original_cli as CallerID,
            ringbackTone:
                details.i_ringback_tone !== undefined
                    ? details.i_ringback_tone
                    : null,
            callPickupAllowed: details.pickup_allowed === YesNo.Yes,
            ringStrategy: details.hunt_sequence as RingStrategy,
            members: (details.assigned_extensions as AssignedExtension[])
                .sort((v, w) =>
                    (v.hunt_order || 0) > (w.hunt_order || 0) ? 1 : -1,
                )
                .map((v) => mapMembers(v)),
            callQueueStatus: !!details.assigned_callqueue,
            announceNumberOfCallersInQueue:
                details.assigned_callqueue?.announce_number_of_callers === YesNo.Yes,
            maximumNumberOfQueuedCallers:
                details.assigned_callqueue?.incoming_capacity ||
                defaultValues.maximumNumberOfQueuedCallers,
            announceEstimatedWaitTime:
                details.assigned_callqueue?.announce_estimated_wait_time ===
                YesNo.Yes,
            averageHandleTime:
                details.assigned_callqueue?.average_duration ||
                defaultValues.averageHandleTime,
            intervalBetweenAnnouncements:
                details.assigned_callqueue?.announcement_interval?.toString() ||
                defaultValues.intervalBetweenAnnouncements?.toString(),
            playOnHoldMusicStatus: !!details.assigned_callqueue?.moh_file_name,
            onHoldMusicFileName: details.assigned_callqueue?.moh_file_name,
            onHoldMusicFile: null,
            dtmfToConfirmQueueExit: details.assigned_callqueue?.dtmf_to_confirm_exit ||
                defaultValues.dtmfToConfirmQueueExit,
            timeOutForCallerInput: details.assigned_callqueue?.dtmf_input_timeout ||
                defaultValues.timeOutForCallerInput,
            dispatchCallsToBusyExtensions: details.assigned_callqueue?.dispatch_to_busy_agents === YesNo.Yes ||
                defaultValues.dispatchCallsToBusyExtensions,
            maximumWaitingTime: details.assigned_callqueue?.maximum_waiting_time,
            maximumRingingTime: details.assigned_callqueue?.maximum_ringing_time,
            onIncomingCallLimit: details.assigned_callqueue?.on_incoming_call_limit
                ? mapCallQueuePromptActionType(details.assigned_callqueue.on_incoming_call_limit)
                : defaultValues.onIncomingCallLimit,
            onMaxRingingTime: details.assigned_callqueue?.on_max_ringing_time
                ? mapCallQueuePromptActionType(details.assigned_callqueue.on_max_ringing_time)
                : defaultValues.onMaxRingingTime,
            onMaxWaitingTime: details.assigned_callqueue?.on_max_waiting_time
                ? mapCallQueuePromptActionType(details.assigned_callqueue.on_max_waiting_time)
                : defaultValues.onMaxWaitingTime,
            queueId: details.assigned_callqueue?.i_c_queue ?? defaultValues.queueId,
            activity_monitoring:details?.wrapUp?.isVisible !== undefined ? activeMonitoring : undefined,
            call_wrap_up_time:callWrapUpTimeVal,
            hunt_while_wrapping_up:activeMonitoring ? details.wrapUp?.hunt_while_wrapping_up : undefined,
            wrap_up_extend_time:callWrapUpExtTimeVal,
            activity_monitoring_lock: details.wrapUp?.activity_monitoring_lock || false,
            call_wrap_up_time_format: secondsToMinutesAndSeconds(callWrapUpTimeVal || 0),
            wrap_up_extend_time_format: secondsToMinutesAndSeconds( callWrapUpExtTimeVal || 0)
        };
    }, [details]);

    const isWrapUpVisible = useMemo(() => {
        return details?.wrapUp?.isVisible || false;
    }, [details]);

    return {
        initialValues,
        data: {
            details,
            isWrapUpVisible: isWrapUpVisible,
            wrapUpLock: false
        },
    };
};
