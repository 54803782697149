import React from 'react';
import {CounterInterface, ThresholdType} from "../../../store/types/CustomerAgreementCondition";
import {roundUp} from "../../../utils/roundUp";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../../styles/Colors";
import {convertUtcToUserLocalTime} from "../../../utils/dateWithTimezoneConversion";
import {useSelector} from "react-redux";
import {ReduxState} from "../../../store/types";
import {convertToMinutesAndSeconds, getLabel} from "./BundleRow.utils";

type BundleRowTooltipProps = {
    product: CounterInterface;
};

const useStyles = makeStyles(() => ({
    tooltipRow: {
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: '24px',
        borderBottom: '1px solid ' + Colors.LightGraySecondary8,
        padding: '8px 0px'
    },
    tooltipLastRow: {
        borderBottom: 'unset',
        textTransform: 'lowercase'
    },
    formatText: {
        display: 'inline-block',
        '&:first-letter': {textTransform: 'uppercase'}
    },
    tooltipContainer: {
        minWidth: 284
    },
}))


function calculateDaysRemaining(targetDateTime: string) {
    const now = new Date();
    const targetDate = new Date(targetDateTime);

    // @ts-ignore
    const differenceInMilliseconds = targetDate - now;

    return roundUp((differenceInMilliseconds / (1000 * 60 * 60 * 24)), 0);
}

const BundleRowTooltip: React.VFC<BundleRowTooltipProps> = ({product}) => {

    const {t} = useTranslation()
    const classes = useStyles()

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const title = t('enums:standardServiceName.' + product.i_service, product.service_name)
    const unitsShort = t(`units:short.${product.unit}`, product.unit, title)

    const displayVal1 = product.threshold_type === ThresholdType.Time ?
        convertToMinutesAndSeconds(Number(product.used), product.unit, product.service_ratio, unitsShort)
        : product.used + ' ' + unitsShort

    const displayVal2 = product.threshold === 'N/A'
        ? t('screens:dashboard.unlimited')
        : roundUp(Number(product.threshold), 2)

    const remaining = product.threshold_type === ThresholdType.Time ?
        convertToMinutesAndSeconds(Number(product.remaining), product.unit, product.service_ratio, unitsShort)
        : product.remaining + ' ' + unitsShort

    const from = convertUtcToUserLocalTime(
        product.period_from,
        timezoneOffset,
        userDateTimeFormat,
    ).split(' ')[0]

    const to = convertUtcToUserLocalTime(
        product.period_to,
        timezoneOffset,
        userDateTimeFormat,
    ).split(' ')[0]

    const diff = calculateDaysRemaining(convertUtcToUserLocalTime(
        product.period_to,
        timezoneOffset,
        userDateTimeFormat,
    ))

    const expireString = `${diff} ${diff > 1 ? t('common:days') : t('common:day')} (${to})`

    return (
        <>
            <div className={classes.tooltipRow}>
                <span>{t('common:service')}</span>
                <span>{title}</span>
            </div>

            <div className={classes.tooltipRow}>
                <span>{t('common:destinationGroup')}</span>
                <span>{product.dg_name}</span>
            </div>

            <div className={classes.tooltipRow}>
                <span>{t('common:serviceUsed')}</span>
                <div>
                    <span
                        data-testid="product-row-used-quantity"
                        data-qa="product-row-used-quantity"
                    >
                        {displayVal1}
                    </span>
                    {' '}
                    <span
                        data-testid="product-row-max-quantity"
                        data-qa="product-row-max-quantity"
                    >/{` ${displayVal2}`}
                    </span>
                </div>
            </div>

            <div className={classes.tooltipRow}>
                <span>{t('screens:ringGroups.availableInBadge')}</span>
                <span>{product.remaining === 'N/A' ? product.remaining : `${remaining}`}</span>
            </div>

            <div className={classes.tooltipRow}>
                <span>{t('common:lastRenewal')}</span>
                <span>{from}</span>
            </div>

            <div className={classes.tooltipRow}>
                <span>{t('common:expiresIn')}</span>
                <span>{expireString}</span>
            </div>

            <div className={classes.tooltipRow}>
                <span>{t('common:bundle')}</span>
                <span>{product.vdp_name}</span>
            </div>

            <div className={classNames(classes.tooltipRow, classes.tooltipLastRow)}>
                <span className={classes.formatText}>{t('common:bundleItem')}</span>
                <span className={classes.formatText}>{getLabel(product)}</span>
            </div>
        </>

    )

};

export default BundleRowTooltip;
