import JSONFormData, {JSONAsyncFormData} from "../../utils/JSONFormData";
import {delay, select} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import {api} from "../services/axios";
import {Async} from "../../services/endpoints";


enum AsyncErrorCode {
    progress = 'Server.AsyncRequest.get_response_of_async_request.request_not_finished'
}

export function* pagedAsyncDataRequest<T>(
    requestURL: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requestParams: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) {

    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONAsyncFormData(session_id, csrf_token);

    body.setParams({
        ...requestParams
    });

    const response: AxiosResponse<{
        bg_request_id: number
    }> = yield api.post(
        requestURL,
        body,
    );


    if (response.data?.bg_request_id) {

        const bodySync = new JSONFormData(session_id, csrf_token);
        bodySync.setParams({
            i_async_request: response.data.bg_request_id,
        })

        let asyncResponse:AxiosResponse<any> |null = null;
        let continueAction = true
        do {

            try {
                yield delay(1000);
                // @ts-ignore
                asyncResponse  = yield api.post(Async.GetResponseRequest, bodySync)
                continueAction = false

            } catch (err) {
                // @ts-ignore
                continueAction = err.response?.data?.faultcode == AsyncErrorCode.progress;

            }

        }
        while (continueAction)


        if(!asyncResponse?.data)
        {
            throw 'Async fetch data error'
        }
        return asyncResponse?.data
    }
}


export function* pagedDataRequest<T>(requestURL: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requestParams: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objFunc: (x: any) => T[]
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const pagingDefinedByRequest = (requestParams?.limit > 0 && requestParams?.limit !== 1000)
        || (requestParams?.offset > 0 && requestParams?.offset !== 0);
    const limit = requestParams?.limit ?? 500;
    let offset = requestParams?.offset ?? 0;
    const useNewLogic = requestParams?.useNewLogic

    const payload = useNewLogic ? {useNewLogic, ...requestParams} : {...requestParams}

    payload.useNewLogic && delete payload.useNewLogic

    const resultArray: T[] = [];

    do {

        body.setParams({
            ...payload,
            limit: limit,
            offset: offset,
            get_total: 1
        });
    
        const response: AxiosResponse<{
            total: number
        }> = yield api.post(
            requestURL,
            body,
        );

        const fetchedArray = objFunc(response.data) ?? [];
        for(const obj of fetchedArray) {
            resultArray.push(obj);
        }

        if(useNewLogic)
        {

            if (fetchedArray.length === 0 || offset >= response.data.total || !pagingDefinedByRequest)
            {
                break;
            }

        } else if(fetchedArray.length === 0 || response.data.total >= resultArray.length  || pagingDefinedByRequest) {
            break;
        }

        offset += limit;
    } while(true);

    return resultArray;
}