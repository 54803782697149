import classNames from 'classnames';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import OverflowTooltip from '../../../components/OverflowTooltip/OverflowTooltip';
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../store";
import {ReduxState} from "../../../store/types";
import {getDurationFromSec} from "../../../utils/transformers";
import BarLoader from "../../../components/BarLoader/BarLoader";
import ExtensionNameDisplay from "../../../layouts/ExtensionNameDisplay";
import {
    calcExtNameFontSize,
    LazyCallVolumesWidgetListViewItemInterface,
    useStyles
} from "./LazyCallVolumesWidgetListViewItem.utils";

const LazyCallVolumesWidgetListViewItem: React.VFC<LazyCallVolumesWidgetListViewItemInterface> = (
    {
        extension,
        numberOfCalls,
        darkMode,
        id,
        period,
        isLoadingChart,
        hideWhenNoCalls
    }) => {

    const classes = useStyles();
    const customTransactionRefreshData = useSelector((state: ReduxState) => state.wallboard.customTransactionRefreshData);
    const element = customTransactionRefreshData.find(el => el.id == id)

    const onlyZero = !element?.details.sum && !element?.details.duration

    const isFetching = useRef(false);
    const previousValue = useRef(period);

    const dispatch = useDispatch();
    const elementRef = useRef(null);

    const [componentState, setComponentState] = useState<{ forceShow: boolean, fetchData: boolean }>({
        forceShow: false,
        fetchData: false
    })

    const fetch = useCallback(() => {

        if (id && period && !componentState.fetchData && isFetching.current) {
            dispatch(actions.fetchCallDataPerExtensionWidgetItem.request({
                i_account: extension.i_account,
                id,
                period,
                callback: () => {
                    setComponentState(prev => ({...prev, forceShow: false, fetchData: false}))
                    isFetching.current = false
                }
            }))
        }

    }, [id, period, componentState.fetchData])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    !isLoadingChart && fetch()
                }
            });
        });

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [id, componentState, isLoadingChart]);


    useEffect(() => {

        if (isLoadingChart && !isFetching.current) {
            setComponentState({forceShow: true, fetchData: false})
        }

        if (period !== previousValue.current && !isFetching.current) {
            setComponentState({forceShow: true, fetchData: false})
            previousValue.current = period
        }

        isFetching.current = true

    }, [period, isLoadingChart]);


    return (
        <>
            <div
                id={(hideWhenNoCalls && (element?.details?.sum ?? 0) === 0 && !componentState.forceShow) ? undefined : id}
                ref={elementRef}
                className={classNames(hideWhenNoCalls && (element?.details?.sum ?? 0) === 0 && !componentState.forceShow ? classes.hide : classes.listViewRow)}>
                <div
                    className={classNames(classes.listViewIconContainer, darkMode && classes.listViewIconContainerDarkMode)}>
                    <div className={classNames(classes.listViewIconText, darkMode && classes.listViewIconTextDarkMode)}
                         style={{
                             fontSize: calcExtNameFontSize(extension.extension_id || extension.id)
                         }}>
                        {extension.extension_id || extension.id}
                    </div>
                </div>

                <ExtensionNameDisplay extension={extension} darkMode={darkMode} dataQa={"tooltip-list-view-name"}/>

                {
                    !element?.loading ? onlyZero ? <>0</> : (
                        <>
                            <div
                                className={classNames(classes.listViewDurationText, darkMode && classes.listViewDurationTextDarkMode)}>
                                <OverflowTooltip
                                    tooltip={getDurationFromSec(element?.details?.duration || 0).string}
                                    text={getDurationFromSec(element?.details?.duration || 0).string}
                                    classes={{text: classNames(classes.listViewDurationText, darkMode && classes.listViewDurationTextDarkMode)}}
                                    dataQa={"tooltip-list-view-duration"}
                                    copy={false}
                                />
                            </div>
                            {numberOfCalls && (
                                <div
                                    className={classNames(classes.callsCounter, darkMode && classes.callsCounterDarkMode)}>
                                    {element?.details.sum}
                                </div>
                            )}
                        </>

                    ) : <BarLoader
                        dataQa={
                            'fetch-calls-loader-' +
                            extension.extension_id
                        }
                    />
                }
            </div>
            <div
                className={classNames(hideWhenNoCalls && (element?.details?.sum ?? 0) === 0 ? classes.hide : classes.listViewRowSeparator)}>
            </div>
        </>
    );
};

export default LazyCallVolumesWidgetListViewItem;